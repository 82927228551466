import React, { useCallback, useState } from "react";
import { AccountSummaryMember } from "../utility/member-portal-api-types";

export enum USER_STATUS {
  ELIGIBLE,
  ENROLLED,
  FEP,
  PENDING,
  EXISTS,
}

export enum ELIGIBILITY_MESSAGE {
  Eligible = "Eligible",
  NotEligible = "NotEligible",
  EmailUnavailable = "EmailUnavailable",
  LoginCreated = "LoginCreated",
  ExistingLoginFound = "ExistingLoginFound",
  PersonIdFound = "PersonIdFound",
  IsSSO = "IsSSO",
  JWTSSO = "JWTSSO",
  PartialEligible = "PartialEligible",
  FreeAccountUpgraded = "FreeAccountUpgraded",
}

export enum PARTIAL_ELIGIBILITY_ERROR_CODE {
  E004 = "E004",
  E005 = "E005",
  E008 = "E008",
  E009 = "E009",
  E010 = "E010",
  E011 = "E011",
  E014 = "E014",
}

export enum USER_PAYROLL_FREQUENCY {
  Weekly = "Weekly",
  BiWeekly = "Biweekly",
  NotApplicable = "NotApplicable",
}

export enum USER_PAYROLL_STATUS {
  Payroll = "Payroll",
  NonPayroll = "NonPayroll",
  NotApplicable = "NotApplicable",
}

export enum PROGRAM_CODE {
  PrimeLTE = "prime_lte",
  WalmartPayrollWeekly = "walmart_payroll_weekly",
  WalmartPayrollBiWeekly = "walmart_payroll",
  PrimeSTD = "prime_std",
  PrimeLGY = "prime_lgy",
  PrimeFYW = "prime_fyw",
  Studio = "studio",
  MutuallyWellEligible = "mutuallywell_eligible",
  NotApplicable = "NotApplicable",
}

export enum MUTUALLY_WELL_FREE_ACCOUNT_TYPE {
  MutuallyWellEligible = "eligible",
  MutuallyWellIneligible = "ineligible",
}

//FEP / REDEMPTION are 'authenticated' DEFAULT is not
export enum USER_FROM {
  FEP,
  REDEMPTION,
  DEFAULT,
}

type PayrollAuthorization = {
  clientCode: string;
  memberPayrollAuthorizationId: string;
  personId: number | undefined;
  authorizationAtUtc: string;
  authorizationStartDateAtAz: string;
  authorizationEndDateAtAz?: string;
  authorizationType: string;
  currentTierEndDate: string | undefined;
  currentPackageName: string;
  currentPackageAmount: number;
  newTierStartDate: string;
  newPackageName: string;
  newPackageAmount: number | undefined;
  createdAtUtc: string;
  updatedAtUtc: string;
};

type DefaultProgram = {
  programCode: string;
  isPrimary: boolean;
  isEligible: boolean;
  isActive: boolean;
  isSuspended: boolean;
  isCancelled: boolean;
  isTransitioning: boolean;
  isCancelling: boolean;
  currentSubscription: string;
  futureSubscription: string;
  homeGym: null;
  homeGymList: [];
  homeGymCode: null;
  tier: string;
};

type DigitalProgram = {
  programCode: string;
  isPrimary: boolean;
  isEligible: boolean;
  isActive: boolean;
  isSuspended: boolean;
  isCancelled: boolean;
  isTransitioning: boolean;
  isCancelling: boolean;
  currentSubscription: string;
};

type EnrolledDependents = {
  consumerId: string;
};

export type IUser = {
  canBeEnrolled: boolean;
  enrolledDependents: EnrolledDependents[];
  isPrimary: boolean;
  isLegacy: boolean;
  isStudioEnrolled: boolean;
  isFWEnrolled: boolean;
  isFWEligible: boolean;
  isEnrolledInDefaultProgram: boolean;
  isEnrolledInDigitalTier: boolean;
  defaultProgram: DefaultProgram;
  digitalProgram: DigitalProgram;
  status: USER_STATUS;
  from: USER_FROM;
  info: UserInfo | undefined;
  personId: number | undefined;
  token: string | undefined;
  isFitnessWorks: boolean | undefined;
  isPayroll: boolean | undefined;
  payrollFrequency: USER_PAYROLL_FREQUENCY;
  isSSO: boolean | undefined;
  isTermed: boolean | undefined;
  consumerId: string;
  clientStateCode: string;
  emailTaken: boolean | undefined;
  payrollAuthorizations: PayrollAuthorization[];
  zuoraAccountId: string | undefined;
  AccountSummaryMember: AccountSummaryMember | undefined;
  hasWHLChoices: boolean | null;
  freeAccountType: string | undefined;
  hasMutuallyWellFreeAccount: boolean | undefined;
};

export type UserInfo = {
  email: string;
  familyName: string | undefined;
  givenName: string | undefined;
  picture: string | undefined;
  preferredUsername: string | undefined;
  sub: string | undefined;
};

interface IUserProvider {
  user: IUser;
  isMocked: boolean;
  isContentfulPreviewOn: boolean;
  mockEnvironment: string;
  setIsContentfulPreviewOn(m: boolean): void;
  setMockEnvironment(m: string): void;
  setIsMocked(m: boolean): void;
  setUser(u: Partial<IUser>): void;
}

export const UserContext = React.createContext<IUserProvider>({
  user: {
    canBeEnrolled: true,
    enrolledDependents: [],
    isPrimary: false,
    isLegacy: false,
    isStudioEnrolled: false,
    isFWEnrolled: false,
    isFWEligible: false,
    isEnrolledInDefaultProgram: false,
    isEnrolledInDigitalTier: false,
    defaultProgram: {
      programCode: "",
      isPrimary: false,
      isEligible: false,
      isActive: false,
      isSuspended: false,
      isCancelled: false,
      isTransitioning: false,
      isCancelling: false,
      currentSubscription: "",
      futureSubscription: "",
      homeGym: null,
      homeGymList: [],
      homeGymCode: null,
      tier: "2",
    },
    digitalProgram: {
      programCode: "",
      isPrimary: false,
      isEligible: false,
      isActive: false,
      isSuspended: false,
      isCancelled: false,
      isTransitioning: false,
      isCancelling: false,
      currentSubscription: "",
    },
    status: USER_STATUS.PENDING,
    isFitnessWorks: false,
    from: USER_FROM.DEFAULT,
    isPayroll: false,
    payrollFrequency: USER_PAYROLL_FREQUENCY.BiWeekly,
    token: undefined,
    personId: undefined,
    isSSO: false,
    isTermed: false,
    consumerId: "",
    clientStateCode: "",
    emailTaken: false,
    info: undefined,
    payrollAuthorizations: [],
    zuoraAccountId: undefined,
    AccountSummaryMember: undefined,
    hasWHLChoices: null,
    freeAccountType: "",
    hasMutuallyWellFreeAccount: undefined,
  },
  isMocked: false,
  isContentfulPreviewOn: false,
  mockEnvironment: "",
  setIsContentfulPreviewOn: () => {
    return;
  },
  setMockEnvironment: () => {
    return;
  },
  setIsMocked: () => {
    return;
  },
  setUser: () => {
    return;
  },
});
/**
 * Checks eligibility/enrollment to determine if user is eligible already
 * Determines where the user came from if possible
 * eligibility.status or enrollment client means theyre eligible
 */
const UserProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [user, setUser] = useState<IUser>({
    canBeEnrolled: true,
    enrolledDependents: [],
    isPrimary: false,
    isLegacy: false,
    isStudioEnrolled: false,
    isFWEnrolled: false,
    isFWEligible: true,
    isEnrolledInDefaultProgram: true,
    isEnrolledInDigitalTier: false,
    defaultProgram: {
      programCode: "",
      isPrimary: false,
      isEligible: false,
      isActive: false,
      isSuspended: false,
      isCancelled: false,
      isTransitioning: false,
      isCancelling: false,
      currentSubscription: "",
      futureSubscription: "",
      homeGym: null,
      homeGymList: [],
      homeGymCode: null,
      tier: "",
    },
    digitalProgram: {
      programCode: "",
      isPrimary: false,
      isEligible: false,
      isActive: false,
      isSuspended: false,
      isCancelled: false,
      isTransitioning: false,
      isCancelling: false,
      currentSubscription: "",
    },
    status: USER_STATUS.PENDING,
    isFitnessWorks: false,
    from: USER_FROM.DEFAULT,
    token: undefined,
    isPayroll: false,
    payrollFrequency: USER_PAYROLL_FREQUENCY.BiWeekly,
    info: undefined,
    personId: undefined,
    isSSO: false,
    isTermed: false,
    consumerId: "",
    clientStateCode: "",
    emailTaken: false,
    payrollAuthorizations: [],
    zuoraAccountId: undefined,
    AccountSummaryMember: undefined,
    hasWHLChoices: null,
    freeAccountType: "",
    hasMutuallyWellFreeAccount: undefined,
  });

  const [isMocked, setIsMocked] = useState<boolean>(
    localStorage.isMocked === "true" ? true : false
  );
  const [isContentfulPreviewOn, setIsContentfulPreviewOn] = useState<boolean>(
    localStorage.isContentfulPreviewOn === "true" ? true : false
  );

  const [mockEnvironment, setMockEnvironment] = useState<string>(
    localStorage.mockEnvironment || ""
  );

  /*
   * This handles b365 redemption flow users. Sets their user state.
   */
  const userValue: IUserProvider = {
    user,
    isContentfulPreviewOn: isContentfulPreviewOn,
    mockEnvironment: mockEnvironment,
    isMocked: isMocked,
    setIsContentfulPreviewOn: useCallback(
      (m: boolean) => {
        setIsContentfulPreviewOn(m);
        localStorage.setItem("isContentfulPreviewOn", String(m));
      },
      [user]
    ),
    setMockEnvironment: useCallback(
      (m: string) => {
        setMockEnvironment(m);
        localStorage.setItem("mockEnvironment", m);
      },
      [user]
    ),
    setIsMocked: useCallback(
      (m: boolean) => {
        setIsMocked(m);
        localStorage.setItem("isMocked", String(m));
      },
      [user]
    ),
    setUser: useCallback(
      (u: Partial<IUser>) => {
        setUser({ ...user, ...u });
      },
      [user]
    ),
  };

  return (
    <UserContext.Provider value={userValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
