import React from "react";
import { useContext } from "react";
import AppContainer from "../components/locations/AppContainer";
import { ThemeContext } from "../context/ThemeProvider";
import chroma from "chroma-js";
import { ThemeProvider } from "react-jss";
import useRouteQuery from "../hooks/useRouteQuery";
import { useState } from "react";
import { useEffect } from "react";
import { AccountContext } from "../context/AccountProvider";
import useGetScreenSize from "../hooks/useGetScreenSize";
import { useConfig } from "../configuration/useConfig";
import { useAuth0 } from "@auth0/auth0-react";
import RedirectPage from "./RedirectPage";
import { useHistory } from "react-router-dom";

const Locations = ({
  packageSelected,
  isLuxuryModal,
  setOpenChooseLuxuryGymModal,
  setShowSuccessToast,
}: {
  packageSelected?: string;
  isLuxuryModal?: boolean;
  setOpenChooseLuxuryGymModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSuccessToast?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { primary } = useContext(ThemeContext);

  const [defaultTier, setDefaultTier] = useState<string | undefined>(undefined);
  const { member } = useContext(AccountContext);
  const { isSmallScreen } = useGetScreenSize();
  const { config } = useConfig();

  const query = useRouteQuery();
  const { isAuthenticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const tier = query.get("TierNumber");
    if (tier) {
      setDefaultTier(tier.toUpperCase());
    } else if (member && member?.eligibilities[0]?.currentSubscription) {
      setDefaultTier(member?.eligibilities[0].currentSubscription?.tier);
    } else if (packageSelected) {
      setDefaultTier(packageSelected);
    } else {
      setDefaultTier("2");
    }
  }, [query, member]);

  const contrastRatio = 3;
  const makeColorAccessible = (color: chroma.Color) => {
    let colorContrast = chroma.contrast(color, "white");
    let newColor = color;
    while (colorContrast < contrastRatio) {
      newColor = chroma(newColor).darken(0.05);
      colorContrast = chroma.contrast(newColor, "white");
    }
    return newColor;
  };
  //
  // Theme Colors
  //

  // Primary
  const primaryColor = chroma(primary);

  const hasGoodContrast =
    chroma.contrast(primaryColor, "white") > contrastRatio;

  const useWhiteOrBlack = hasGoodContrast ? "white" : "black";

  const canHoverDarker = chroma.deltaE(primaryColor, "black") > 40;

  const primaryHover = canHoverDarker
    ? primaryColor.darken(0.25)
    : primaryColor.brighten(1);
  // Primary Accessible
  const primaryA11y = hasGoodContrast
    ? primaryColor
    : makeColorAccessible(primaryColor);

  const hasGoodContrastA11y =
    chroma.contrast(primaryA11y, "white") > contrastRatio;

  const useWhiteOrBlackA11y = hasGoodContrastA11y ? "white" : "black";

  const canHoverDarkerA11y = chroma.deltaE(primaryA11y, "black") > 40;

  const primaryA11yHover = canHoverDarkerA11y
    ? primaryA11y.darken(0.25)
    : primaryA11y.brighten(1);

  if (!isAuthenticated && config["client"] !== "mutual-of-omaha") {
    history.push("/login");
    return (
      <RedirectPage message="User is not logged in, Redirecting to Login" />
    );
  }

  return (
    <ThemeProvider
      theme={{
        primary: {
          color: primaryColor,
          hoverColor: primaryHover,
          textColor: useWhiteOrBlack,
          hasGoodContrast,
        },
        accessible: {
          primary: {
            color: primaryA11y,
            hoverColor: primaryA11yHover,
            textColor: useWhiteOrBlackA11y,
            hasGoodContrast: hasGoodContrastA11y,
          },
        },
      }}
    >
      <div className={isLuxuryModal || isSmallScreen ? "" : "full-height"}>
        <AppContainer
          tier={defaultTier}
          isLuxuryModal={isLuxuryModal}
          setOpenChooseLuxuryGymModal={setOpenChooseLuxuryGymModal}
          setShowSuccessToast={setShowSuccessToast}
        />
      </div>
    </ThemeProvider>
  );
};

export default Locations;
