import { useContext, useEffect, useState } from "react";
import { Tile } from "./Tile";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MembershipCard from "../MembershipCard";
import { AccountContext } from "../../../context/AccountProvider";
import { Link } from "react-router-dom";
import digitalPlan from "./assets/digitalPlan.svg";
import studioRewards from "./assets/studioRewards.svg";
import { PROGRAM_CODE, UserContext } from "../../../context/UserProvider";
import useGetPrograms from "../../../hooks/useGetPrograms";
import useGetScreenSize from "../../../hooks/useGetScreenSize";

const PlanTile = () => {
  const { member, memberCardBaseImage } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const { isExtraSmallScreen } = useGetScreenSize();

  const { productRatePlans, programCode } = useGetPrograms();

  const currentPlan = productRatePlans.filter(
    (plan) =>
      plan.tier.toString() ===
      member?.eligibilities[0].currentSubscription?.tier?.toString()
  );
  const planDescription = currentPlan[0]?.description;
  const parser = new DOMParser();
  const doc = parser.parseFromString(planDescription, `text/html`);
  const gymCountDescription =
    doc.querySelector(".std-ct")?.innerHTML ??
    doc.querySelector(".lgy-ct")?.innerHTML;
  const digitalContentDescription = doc.querySelector(".digital")?.innerHTML;
  const studioDescription = doc.querySelector(".studio")?.innerHTML;

  const subscriptionTier = member?.eligibilities[0].currentSubscription
    ? member!.eligibilities[0].currentSubscription.tier
    : null;

  const [showManagePlan, setShowManagePlan] = useState(false);

  /*This will have to be updated as to properly fit the myPlan and other functions for all tiers/names*/
  const getPlanName = (): string => {
    if (subscriptionTier === "0") {
      return "Digital";
    } else if (
      member?.eligibilities[0].currentSubscription?.isLegacyProduct === true
    ) {
      return "Fitness Pass Legacy";
    } else if (
      member?.eligibilities.some(
        (e) =>
          e.productCode === "261" &&
          !member.eligibilities.some((e) => e.productCode === "226")
      )
    ) {
      return "Fitness Works";
    } else if (
      member?.eligibilities.some(
        (e) =>
          e.productCode === "260" &&
          !member.eligibilities.some((e) => e.productCode === "226")
      )
    ) {
      return "Studio Rewards";
    } else {
      return "Fitness Package";
    }
  };

  useEffect(() => {
    const isDependent = !member?.eligibilities[0].isPrimary;
    let shouldShowManagePlan = true;
    if (user.isFWEnrolled || isDependent) shouldShowManagePlan = false;
    setShowManagePlan(shouldShowManagePlan);
  }, [member]);

  const printCard = () => {
    const myWindow: Window | null = window.open("");
    if (myWindow) {
      myWindow.document.write(
        `<img src=data:image/png;base64,${memberCardBaseImage} alt="card"/>`
      );
      setTimeout(() => {
        myWindow.focus();
        myWindow.print();
      }, 10);
      myWindow.document.close();
    }
  };

  const downloadCard = () => {
    const fileName = `membership-card.png`;
    const link = document.createElement("a");
    link.href = "data:image/png;base64," + memberCardBaseImage;
    link.download = fileName;
    document.body.append(link);
    link.click();
  };

  const myPlan = () => {
    switch (getPlanName()) {
      case "Fitness Package":
        return (
          <>
            <h2 className="mt-3">
              {programCode === PROGRAM_CODE.PrimeSTD ||
              programCode === PROGRAM_CODE.PrimeFYW ||
              programCode === PROGRAM_CODE.PrimeLGY
                ? member?.eligibilities[0].currentSubscription?.ratePlanName +
                  " Fitness Package"
                : programCode === PROGRAM_CODE.PrimeLTE
                ? "Fitness Pass " +
                  member?.eligibilities[0].currentSubscription?.ratePlanName
                : member?.eligibilities[0].currentSubscription?.ratePlanName}
            </h2>

            <ul className="package-list-horizontal">
              {gymCountDescription && (
                <li
                  className="package-list-horizontal__package"
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <div
                    className="package-list-horizontal__gym-count"
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <span>Access&nbsp;</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: gymCountDescription,
                      }}
                    ></span>
                  </div>
                </li>
              )}
              {digitalContentDescription && (
                <li className="package-list-horizontal__package">
                  {digitalContentDescription}
                </li>
              )}
              {member?.eligibilities[0].productCode === "226" &&
                studioDescription && (
                  <li className="package-list-horizontal__package">
                    {studioDescription}
                  </li>
                )}
            </ul>
          </>
        );
        break;
      case "Digital":
        return (
          <>
            <h2 className="mt-3">Digital Only Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "Fitness Pass Legacy":
        return (
          <>
            <h2 className="mt-3">LEGACY POWER FITNESS PACKAGE</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 11k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "Studio Rewards":
        return (
          <>
            <h2 className="mt-3">Studio Rewards Only</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                30% Off Every 10th Class
              </li>
            </ul>
          </>
        );
        break;
      case "Fitness Works":
        return (
          <>
            <h2 className="mt-3">Fitness Works</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Fitness Works Program
              </li>
            </ul>
          </>
        );
        break;
      default:
        return (
          <>
            <h2 className="mt-3 align-self-center">No Current Packages</h2>
          </>
        );
        break;
    }
  };

  const cardBody = () => {
    if (memberCardBaseImage !== "" && memberCardBaseImage === undefined)
      return null;
    switch (getPlanName()) {
      case "Digital":
        return (
          <div>
            <img
              src={digitalPlan}
              alt="Woman following yoga class from her laptop"
              width={"44%"}
            />
            <h6>
              <b>Want to add gym access?</b>
            </h6>
            <p>
              Visit the{" "}
              <Link to="/account/manage" className="myPlan-tile__link">
                <span>Manage Plan page</span>
              </Link>{" "}
              to add gym access at any time.
            </p>
          </div>
        );
        break;
      case "Studio Rewards":
        return (
          <div>
            <img
              src={studioRewards}
              alt="Woman stretching on yoga mat"
              width={"54%"}
            />
            <h6>
              <b>HOW TO BOOK A STUDIO CLASS</b>
            </h6>
            <p>
              Download the Well onTarget mobile app to book studio pay-as-you-go
              classes.
            </p>
          </div>
        );
        break;
      default:
        return (
          <div>
            <MembershipCard />
            <span className="title mt-3">HOW TO USE YOUR CARD</span>
            <br />
            {getPlanName() === "Fitness Works" ? (
              <span>Show this card at any Fitness Works eligible gym.</span>
            ) : (
              <span>
                Show this card at any gym in your fitness package network.
              </span>
            )}
            <div className="myPlan-tile__buttons mt-3">
              <Button
                variant="outline-primary"
                className="btn btn-outline-primary"
                onClick={() => downloadCard()}
                style={{
                  color: "var(--clientPrimary)",
                  borderColor: "var(--clientPrimary)",
                  borderRadius: "3px",
                  fontSize: isExtraSmallScreen ? "9px" : "14px",
                  width: isExtraSmallScreen ? "96px !important" : "",
                }}
              >
                <FontAwesomeIcon icon={faDownload} /> Download
              </Button>
              <Button
                variant="outline-primary"
                className="btn btn-outline-primary"
                onClick={() => printCard()}
                style={{
                  color: "var(--clientPrimary)",
                  borderColor: "var(--clientPrimary)",
                  borderRadius: "3px",
                  fontSize: isExtraSmallScreen ? "9px" : "14px",
                  width: isExtraSmallScreen ? "96px !important" : "",
                }}
              >
                <FontAwesomeIcon icon={faPrint} /> Print
              </Button>
            </div>
          </div>
        );
        break;
    }
  };

  return (
    <Tile className="widget__card">
      <div className="myPlan-tile">
        <Tile.THeader>
          <div>
            <FontAwesomeIcon icon={faClipboardCheck} /> <b>MY PLAN</b>
          </div>
          {showManagePlan && (
            <Link to="/account/manage" className="a-link">
              <span
                style={{
                  color: "var(--clientPrimary)",
                  fontWeight: "500",
                }}
              >
                Manage Plan
              </span>
            </Link>
          )}
        </Tile.THeader>
        <Tile.TBody>
          {myPlan()}
          {cardBody()}
        </Tile.TBody>
      </div>
    </Tile>
  );
};
export default PlanTile;
