import { PropsWithChildren, useContext, useState } from "react";
import { Button, Modal, ModalProps, Spinner } from "react-bootstrap";
import { useConfig } from "../../../configuration/useConfig";
import { UserContext } from "../../../context/UserProvider";
import showToastMessage from "../../../utility/ProfileToast";
import { discardSubscription } from "../DiscardCancellationModal";
import moment from "moment";
import { AccountSummaryMember } from "../../../utility/member-portal-api-types";

interface DiscardSubscriptionChangeProperties {
  onClose: (success?: boolean) => void;
  dependent: AccountSummaryMember;
  nextPaymentDate?: string;
  hasUnderageDependents: boolean;
  isLoadingDependents: boolean;
}

function Element({ children, ...props }: PropsWithChildren<ModalProps>) {
  return (
    <Modal show {...props} centered>
      <Modal.Header>
        <Modal.Title>
          <h2>Discard Changes?</h2>
          {props.onHide && (
            <span style={{ cursor: "pointer" }} onClick={props.onHide}>
              X
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
}

export function DiscardUnderagePlanChangeModal({
  nextPaymentDate,
  dependent,
  onClose,
  isLoadingDependents,
}: DiscardSubscriptionChangeProperties) {
  const { client } = useConfig();
  const { user } = useContext(UserContext);
  const [isDiscarding, setIsDiscarding] = useState(false);

  const currentSubscription = dependent.eligibilities[0]?.currentSubscription;
  const futureSubscription = dependent.eligibilities[0]?.futureSubscription;
  const personId = dependent.personId;
  const isDependent = true;
  const dateOfPlanChange = nextPaymentDate
    ? nextPaymentDate
    : dependent.eligibilities[0]?.memberEffectiveDate;
  const isPayroll = user.isPayroll;
  if (
    !(
      currentSubscription &&
      futureSubscription &&
      personId &&
      dateOfPlanChange &&
      isPayroll !== undefined
    )
  ) {
    return <></>;
  }

  const onDiscard = async () => {
    try {
      setIsDiscarding(true);
      await discardSubscription(
        personId,
        futureSubscription,
        isPayroll,
        isDependent,
        currentSubscription
      );
      showToastMessage("Your account was successfully updated", true, client);
      onClose(true);
    } catch {
      onClose(false);
    }
  };

  if (isDiscarding) {
    return (
      <Element style={{ pointerEvents: "none" }}>
        <Modal.Body style={{ minHeight: "160px" }}>
          <div className="d-flex flex-column justify-content-center">
            <div className="center-loading" style={{ marginTop: "0" }}>
              <Spinner animation="border" />
            </div>
            <span className="text-center mt-3">
              Processing... This may take a minute.
            </span>
            <p className="text-center">Please do not refresh the page.</p>
          </div>
        </Modal.Body>
      </Element>
    );
  }

  return (
    <Element onHide={() => onClose()}>
      <Modal.Body>
        {isLoadingDependents ? (
          <>
            <p className="text-center mb-3">Please wait...</p>
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          </>
        ) : (
          <>
            <p>
              You recently changed your fitness package from{" "}
              <b>{currentSubscription.ratePlanName}</b> to{" "}
              <b>{futureSubscription.ratePlanName}</b>, which is scheduled to
              take place on{" "}
              <b>{moment(dateOfPlanChange).format("MM/DD/YYYY")}</b>. Would you
              like to discard this change?
            </p>
          </>
        )}
      </Modal.Body>

      {!isLoadingDependents && (
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "white", width: "48%" }}
            onClick={() => onClose()}
          >
            <span style={{ color: "var(--primary)" }}>Close</span>
          </Button>
          <Button
            variant="primary"
            style={{ width: "48%" }}
            onClick={onDiscard}
          >
            Discard Changes
          </Button>
        </Modal.Footer>
      )}
    </Element>
  );
}

export default DiscardUnderagePlanChangeModal;
