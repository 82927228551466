import React, { useContext, useEffect, ReactNode } from "react";
import { TokenContext } from "../../context/Auth0TokenProvider";
import axiosInstance, {
  axiosWHLChoicesLocationsInstance,
} from "../../../src/axios.instance";
import { axiosNetworkInstance } from "../../../src/axios.instance";
import { useConfig } from "../../configuration/useConfig";
import { useWHLLocationsToken } from "../../context/WHLLocationsTokenProvider";
interface AxiosInterceptorProps {
  children: ReactNode;
}
const AxiosInterceptor: React.FC<AxiosInterceptorProps> = ({ children }) => {
  const token = useContext(TokenContext);
  const whlLocationsToken = useWHLLocationsToken();
  const dynamicConfig = useConfig();

  useEffect(() => {
    // Interceptor for Authorization header
    const axiosInstanceInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Interceptor for WHL Location header
    const axiosWHLChoicesLocationsInterceptor =
      axiosWHLChoicesLocationsInstance.interceptors.request.use(
        (config) => {
          if (whlLocationsToken) {
            config.headers.Authorization = `Bearer ${whlLocationsToken}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

    // Interceptor for AWS Networks API
    const AxiosNetworkInterceptor =
      axiosNetworkInstance.interceptors.request.use(
        (config) => {
          config.headers[`tivity-client`] = `${dynamicConfig.config["client"]}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    return () => {
      axiosInstance.interceptors.request.eject(axiosInstanceInterceptor);
      axiosNetworkInstance.interceptors.request.eject(AxiosNetworkInterceptor);
      axiosWHLChoicesLocationsInstance.interceptors.request.eject(
        axiosWHLChoicesLocationsInterceptor
      );
    };
  }, [token, whlLocationsToken]);
  return <>{children}</>;
};
export default AxiosInterceptor;
