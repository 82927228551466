import React from "react";
import { Icon } from "../../resources/Icons";
import { useConfig } from "../../configuration/useConfig";
import { NavLink } from "react-router-dom";

const WholeHealthLiving = () => {
  const { config } = useConfig();

  return (
    <div className="my-3 d-flex">
      <div
        className="d-flex w-100 rounded flex-md-row flex-column"
        style={{ backgroundColor: "var(--clientPrimary)" }}
      >
        <div className="d-flex align-items-center justify-content-center w-50 w-md-100">
          <Icon
            name="whole-health-living"
            width="279px"
            height="61px"
            fill="#fff"
            className="mx-md-5 mx-3 my-3 mr-2 my-md-0 my-4 fill-white"
          ></Icon>
        </div>
        <div className="d-flex flex-column white px-4 px-md-0 w-50 w-md-100 mx-3">
          <h5
            className="font-weight-bold mt-md-5 mb-3 white w-100"
            style={{ fontSize: "18px" }}
          >
            Get Wellness Discounts
          </h5>
          <p
            className="mb-2 w-100"
            style={{ paddingRight: 20, color: "#FFFFFF", fontSize: "14px" }}
          >
            Complement your fitness routine with discounts on wellness products
            and services offered by WholeHealth Living Choices.
          </p>
          {config.client === "mutual-of-omaha" ? (
            <NavLink to="/account/choices">
              <button
                type="submit"
                className="btn-outline-primary align-self-start btn my-3 px-4 py-2"
                style={{
                  width: "fit-content",
                  fontSize: "14px",
                  color: "var(--clientPrimary)",
                  borderColor: "var(--clientPrimary)",
                }}
              >
                Learn More
                <Icon
                  name="external-link"
                  width="20px"
                  height="20px"
                  className="ml-2 mb-1"
                  style={{ color: "var(--clientPrimary)" }}
                  fill="var(--clientPrimary)"
                ></Icon>
              </button>
            </NavLink>
          ) : (
            <a
              target="_blank"
              href={
                config.client === "hcsc"
                  ? `https://www.whlchoices.com/#/register?referUrl=https://www.bcbsilforyourhealth.com/`
                  : config.client === "bcbsa"
                  ? `https://www.whlchoices.com/#/register?referUrl=https://fitnessyourway.tivityhealth.com/`
                  : ""
              }
            >
              <button
                type="submit"
                className="btn-outline-primary align-self-start btn my-3 px-4 py-2"
                style={{
                  width: "fit-content",
                  fontSize: "14px",
                  color: "var(--clientPrimary)",
                  borderColor: "var(--clientPrimary)",
                }}
              >
                Learn More
                <Icon
                  name="external-link"
                  width="20px"
                  height="20px"
                  className="ml-2 mb-1"
                  style={{ color: "var(--clientPrimary)" }}
                  fill="var(--clientPrimary)"
                ></Icon>
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default WholeHealthLiving;
