import React, { useContext } from "react";
import { Tile } from "./Tiles/Tile";
import { Button } from "react-bootstrap";
import ReEnrollImg from "./Tiles/assets/reenroll_banner.png";
import { Link } from "react-router-dom";
import { AccountContext } from "../../context/AccountProvider";
import { momentStartOfDay } from "../../utility/util";

const WaivedEnrollmentBanner = () => {
  const { member } = useContext(AccountContext);
  const now = momentStartOfDay();
  const offerInvalid = momentStartOfDay(member?.eligibilities[0].memberTermDate)
    .add(29, "days")
    .format("MM/DD/YYYY");

  const daysLeft = () => {
    let daysString = `${now.diff(offerInvalid, "days") * -1} days`;
    if (now.diff(offerInvalid, "days") * -1 === 1) {
      daysString = `day`;
    }
    return daysString;
  };
  return (
    <div className="widget d-flex flex-wrap flex-row mb-3">
      <Tile className="reenroll-banner">
        <Tile.THeader className="reenroll-banner__header">
          <div className="h-100">
            <img width="100%" height="100%" src={ReEnrollImg} alt="enroll" />
          </div>
        </Tile.THeader>
        <Tile.TBody className="reenroll-banner__body">
          <div>
            <h5 className="text-left">
              <b>Re-Enroll Soon and Pay No Fees!</b>
            </h5>
            <hr />
            <p style={{ fontSize: `0.8rem` }} className="text-left p-0 mb-0">
              As a courtesy for being a member, we'll waive enrollment fees if
              you re-enroll within the next {daysLeft()}. This{" "}
              <b>offer expires on {offerInvalid}.</b>
            </p>
          </div>
        </Tile.TBody>
        <Tile.TFooter
          className="reenroll-banner__footer"
          // className="d-flex mb-2 justify-content-center flex-column"
        >
          <div className="d-flex justify-content-between align-items-center">
            <Button
              as={Link}
              style={{ color: "white" }}
              className="btn btn-primary align-self-left btn-sm w-90 upgrade-banner__button"
              to={"/enroll/package"}
            >
              Re-Enroll
            </Button>
          </div>
        </Tile.TFooter>
      </Tile>
    </div>
  );
};
export default WaivedEnrollmentBanner;
