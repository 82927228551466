import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { axiosWHLChoicesIDPInstance } from "../axios.instance";
import { useConfig } from "../configuration/useConfig";

interface Auth0TokenProviderProps {
  children: ReactNode;
}

export const WHLChoicesTokenContext = createContext<string | null>(null);

export const WHLChoicesTokenProvider: React.FC<Auth0TokenProviderProps> = ({
  children,
}) => {
  // const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);
  const { config } = useConfig();

  const getToken = async () => {
    if (config["client"] === "mutual-of-omaha") {
      try {
        const response = await axiosWHLChoicesIDPInstance.request({
          url: `/identity/connect/token`,
          method: "POST",
          data: {
            grant_type: "password",
            scope: "whl",
            client_id: config["whlChoicesIDPTokenClientId"],
            client_secret: config["whlChoicesIDPTokenClientSecret"],
            username: config["whlChoicesIDPTokenUsername"],
            password: config["whlChoicesTokenPassword"],
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        const fetchedToken = response.data.access_token;
        setToken(fetchedToken);
      } catch (error) {
        console.error("Error getting token", error);
      }
    }
  };
  useEffect(() => {
    getToken();
  }, []);

  return (
    <WHLChoicesTokenContext.Provider value={token}>
      {children}
    </WHLChoicesTokenContext.Provider>
  );
};

export const useAuthToken = () => {
  return useContext(WHLChoicesTokenContext);
};
