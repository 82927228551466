import React from "react";
import { PROGRAM_CODE } from "../../context/UserProvider";
import useGetPrograms from "../../hooks/useGetPrograms";

// Legacy Power Plan
export const legacyPowerPlan = (planId?: string) => {
  return {
    billingPeriod: "Month",
    description: "LEGACY",
    id: "",
    credits: 0,
    isBestValue: false,
    name: "LEGACY POWER",
    recurringPrice: 29,
    recurringRatePlanChargeId: planId ?? "",
    setupFee: 0,
    setupRatePlanChargeId: "",
    tier: 3,
  };
};

const BundlesDropDown = ({
  planId,
  legacyMember = false,
  filterOutStudio = false,
}: {
  planId?: string;
  membersPage?: boolean;
  legacyMember?: boolean;
  filterOutStudio?: boolean;
}) => {
  const {
    error,
    loading,
    productRatePlans: plans,
    addendum,
  } = useGetPrograms();

  const productRatePlans = React.useMemo(() => {
    return filterOutStudio
      ? plans.filter((p) => p.name.toLowerCase() !== PROGRAM_CODE.Studio)
      : plans;
  }, [plans, filterOutStudio]);

  let legacyPlan;
  if (loading) {
    return <option>Loading...</option>;
  }
  if (error) {
    return <option>Error getting pricing</option>;
  }
  if (legacyMember) {
    // retrieve index of plan tier 3
    let planIdx = 0;
    let counter = 0;

    legacyPlan = productRatePlans.filter((plan) => {
      if (plan.tier === 3) {
        planIdx = counter;
        return false;
      }
      counter += 1;
      if (plan.name.toLowerCase() === PROGRAM_CODE.Studio) {
        return false;
      }
      return true;
    });
    legacyPlan.splice(planIdx, 0, legacyPowerPlan(planId));
  }

  return (
    <>
      {/* {termed ? (
        <option value={"termedDefault"} key={"termedDefault"} disabled>
          Please Select a Package
        </option>
      ) : null} */}
      {(!legacyMember ? productRatePlans : legacyPlan).map((plan) => {
        return (
          <option
            defaultValue={planId}
            value={plan.recurringRatePlanChargeId}
            key={plan.id}
          >
            {plan.name.toUpperCase()}{" "}
            {`$${plan.recurringPrice.toFixed(2) ?? 0}/${addendum} `}
          </option>
        );
      })}
    </>
  );
};

export default BundlesDropDown;
