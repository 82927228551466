import { useState } from "react";
import { GeoCode } from "../api/platform/Queries";
import { LocationData, StudioData } from "../api/platform/Queries";
import { axiosNetworkInstance, axiosWHLChoicesLocationsInstance } from "../axios.instance";
import { useConfig } from "../configuration/useConfig";
import getProgramCode from "../utility/getProgramCode";
import { PROGRAM_CODE } from "../context/UserProvider";
import { WHLVendorLocationResponse } from "../utility/member-portal-api-types";
import  {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

// import axios from "axios";

// TODO: authentication
// TODO: Other query parameters
const useGetLocations = ({
  tier,
  amenities,
  studio,
  radius,
  womenOnlyFilter,
  isLuxuryModal,
  isMutuallyWellEligible,
}: {
  tier: string;
  amenities: string[];
  studio: string;
  radius: number;
  womenOnlyFilter: boolean;
  isLuxuryModal?: boolean;
  isMutuallyWellEligible?: boolean;
}) => {
  const [locations, setLocations] = useState<LocationData[]>([]);
  const [whlLocations, setWHLVendorLocations] = useState<WHLVendorLocationResponse[]>([]);
  const [studios, setStudios] = useState<StudioData[]>([]);
  const [distances, setDistances] = useState([]);
  const [geoCode] = useState<GeoCode>({
    latitude: 44.96,
    longitude: -103.77,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {config} = useConfig();

  const programCode = isMutuallyWellEligible ? getProgramCode(PROGRAM_CODE.MutuallyWellEligible) : getProgramCode(config["client"])

  const getQuery = () => {
    let query = "";
    if (amenities.length > 0) {
      query += "&amenities=" + amenities.join(",");
    }
    if (radius !== 0) {
      query += "&Radius=" + radius;
    }
    if (tier) {
      query += "&TierNumber=" + tier;
    }
    //add female only
    if (womenOnlyFilter !== false) {
      query += "&womenOnlyFilter=" + womenOnlyFilter;
    }
    return query;
  };

  const getStudioQuery = () => {
    let query = "";
    if (radius !== 0) {
      query += "&Radius=" + radius;
    }
    if (studio !== "") {
      query += "&searchText=" + studio;
    }
    return query;
  };

  const getStudios = {
    run: async (lat: number, lng: number) => {
      // encodeURI(params);
      setLoading(true);
      setError(false);
      const maxResults = 200;

      const params = new URLSearchParams({
        latitude: lat.toString(),
        longitude: lng.toString(),
        maxResults: maxResults.toString(),
      });

      if (radius !== 0) {
        params.set("radius", radius.toString());
      }

      const response = await axiosNetworkInstance.get("/studios/search", {
        params,
      });
      if (response.data) {
        const distances = response.data.data.list.map((item) => item.distance);
        setDistances(distances);
        const studios = response.data.data.list.map(
          (item) => item.locationViewModel
        );
        setStudios(studios);
      }
    },
    runWithAddress: async (address: string) => {
      setLoading(true);
      setError(false);

      const params = new URLSearchParams({
        Address: address,
      });

      const response = await axiosNetworkInstance.get("/studios/search", {
        params,
      });
      if (response.data) {
        const distances = response.data.data.list.map((item) => item.distance);
        setDistances(distances);
        const studios = response.data.data.list.map(
          (item) => item.locationViewModel
        );
        setStudios(studios);
      }
    },
  };

  // TODO: try to reuse this const getLocations for studio API call and
  // pass maybe a parameter to know if is a studio call or not
  const getLocations = {
    run: async (lat: number, lng: number, isStudio: boolean) => {
      // encodeURI(params);
      setLoading(true);
      setError(false);
      let query;
      if (!isStudio) {
        query =
          `/locations/search?ProgramCode=${programCode}&Latitude=` +
          lat +
          "&Longitude=" +
          lng +
          encodeURI(getQuery());
      } else {
        query =
          "/studios/search?latitude=" +
          lat +
          "&longitude=" +
          lng +
          encodeURI(getStudioQuery());
      }

      await axiosNetworkInstance
        .get(query, {
        })
        .then((response) => {
          if (!isStudio && response.data) {
            const distances = response.data.data.list.map(
              (item) => item.distance
            );
            setDistances(distances);
            const locations = response.data.data.list.map(
              (item) => item.location
            );
            let filteredLocations;
            if(isLuxuryModal){
              filteredLocations = locations.filter((location) => location.isHomeLocationTier);
            } else {
              filteredLocations = locations;
            }
            setLocations(filteredLocations);
          }
          if (isStudio && response.data) {
            const distances = response.data.data.list.map(
              (item) => item.distance
            );
            setDistances(distances);
            const studios = response.data.data.list.map(
              (item) => item.locationViewModel
            );
            setStudios(studios);
          }
        })
        .catch((error) => {
          setLocations([]);
          setStudios([]);
          setError(true);
          console.log(error.toJSON());
        });
    },
    runWithAddress: async (
      address: string,
      isStudio: boolean
    ) => {
      setLoading(true);
      setError(false);
      let query;

      if (!isStudio) {
        query =
          `locations/search?ProgramCode=${programCode}&Address=` +
          address +
          encodeURI(getQuery());
      } else {
        query =
          "/studios/search?Address=" + address + encodeURI(getStudioQuery());
      }

      await axiosNetworkInstance
        .get(query, {
        })
        .then((response) => {
          if (!isStudio && response.data) {
            const distances = response.data.data.list.map(
              (item) => item.distance
            );
            setDistances(distances);

            const locations = response.data.data.list.map(
              (item) => item.location
            );
            let filteredLocations;
            if(isLuxuryModal){
              filteredLocations = locations.filter((location) => location.isHomeLocationTier);
            } else {
              filteredLocations = locations;
            }
            setLocations(filteredLocations);
          }
          if (isStudio && response.data) {
            const distances = response.data.data.list.map(
              (item) => item.distance
            );
            setDistances(distances);

            const studios = response.data.data.list.map(
              (item) => item.locationViewModel
            );
            setStudios(studios);
          }
        })
        .catch((error) => {
          setLocations([]);
          setStudios([]);
          setError(true);
          console.log(error.toJSON());
        });
    },
  };

  const getWHLVendorLocations = {
    run: async (lat: number, lng: number) => {
      setLoading(true);
      setError(false);

      const data = JSON.stringify({
        "ClientID": "2",
        "Latitude": lat,
        "Longitude": lng,
        "Distance": 10
      });

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: { 
          'Content-Type': 'application/json',
        },
        data : data
      };

      axiosWHLChoicesLocationsInstance.request(config)
      .then((response) => {
        setWHLVendorLocations(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  runWithAddress: async (address: string) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setLoading(true);
    setError(false);

    const data = JSON.stringify({
      "ClientID": "2",
      "Latitude": latLng.lat,
      "Longitude": latLng.lng,
      "Distance": 10
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      headers: { 
        'Content-Type': 'application/json',
      },
      data : data
    };

    axiosWHLChoicesLocationsInstance.request(config)
    .then((response) => {
      setWHLVendorLocations(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  },
};

  return {
    locations,
    distances,
    studios,
    setStudios,
    setLocations,
    setWHLVendorLocations,
    whlLocations,
    getLocations,
    getStudios,
    getWHLVendorLocations,
    geoCode,
    loading,
    error,
    amenities,
    radius,
  };
};

export default useGetLocations;
