import { useEffect, useState } from "react";

const useGetScreenSize = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);
  const [isMobileScreenSize, setIsMobileScreenSize] = useState(
    window.innerWidth < 768
  );
  const [isExtraSmallScreen, setIsExtraSmallScreen] = useState(window.innerWidth < 425);

  const adjustScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 1024);
    setIsMobileScreenSize(window.innerWidth < 768);
    setIsExtraSmallScreen(window.innerWidth < 425);
  };
  useEffect(() => {
    window.addEventListener("resize", adjustScreenSize);
    return () => {
      window.removeEventListener("resize", adjustScreenSize);
    };
  }, []);

  return { isSmallScreen, isMobileScreenSize, isExtraSmallScreen };
};

export default useGetScreenSize;
