import { useFormikContext, getIn } from "formik";
import React, { Fragment, useContext, useCallback, useState } from "react";
import { useEffect } from "react";
import { Alert, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  PROGRAM_CODE,
  USER_PAYROLL_FREQUENCY,
  USER_PAYROLL_STATUS,
  UserContext,
} from "../../../context/UserProvider";
import useCheckOrder from "../../../hooks/useCheckOrder";
import { AccountContext } from "../../../context/AccountProvider";
import useIncontact from "../../../hooks/useIncontact";
import useSummary from "../../../hooks/useSummary";
import useZuora from "../../../hooks/useZuora";
import useZuoraPayments from "../../../hooks/useZuoraPayments";
import FooterButtons from "../components/FooterButtons";
import Header from "../components/Header";
import ProcessingOrderModal from "../components/ProcessingOrderModal";
import StatusModal from "../components/StatusModal";
import Summary from "../components/Summary";
import { EligibilityFlow, FormikWizardState, IAddressFields } from "../types";
import axiosInstance, { axiosBaseInstance } from "../../../axios.instance";
import { useConfig } from "../../../configuration/useConfig";
import useContentful from "../../../hooks/useContentful";
import { contentfulConfig } from "../../../client";
import styles from "./Checkout.module.scss";
import { axiosPolling } from "axios-polling";
import FailPaymentModal from "../../account/Billing/FailPaymentModal";
import SomethingWentWrong from "../../../pages/SomethingWentWrong";
import { ImpersonationContext } from "../../../context/ImpersonationProvider";
import useGetScreenSize from "../../../hooks/useGetScreenSize";
import { useAuthToken } from "../../../context/Auth0TokenProvider";
import { v4 as uuidv4 } from "uuid";
import { PlanDependent } from "../components/ManageMembers";
import getDependentProgramCode from "../../../utility/getDependentProgramCode";
import { AccountSummaryMember } from "../../../utility/member-portal-api-types";

function createNewMemberFromForm(
  info: FormikWizardState<EligibilityFlow>,
  client: unknown,
  memberInfo: AccountSummaryMember,
  clientStateCode?: string,
  billingInfo?: IAddressFields,
  soldToInformation?: IAddressFields
) {
  const isStudio = info?.package?.sku === PROGRAM_CODE.Studio;
  return {
    firstName: info.eligibility?.firstName,
    lastName: info.eligibility?.lastName,
    billToContact: {
      lastName: info.eligibility?.lastName,
      firstName: info.eligibility?.firstName,
      address1: billingInfo?.street1,
      address2: billingInfo?.street2,
      city: billingInfo?.city,
      postalCode: billingInfo?.zip,
      state: billingInfo?.state,
    },
    soldToContact: {
      firstName: info.eligibility?.firstName,
      lastName: info.eligibility?.lastName,
      address1: soldToInformation?.street1,
      address2: soldToInformation?.street2,
      city: soldToInformation?.city,
      postalCode: soldToInformation?.zip,
      state: soldToInformation?.state,
      email: info.eligibility?.email,
    },
    personId: memberInfo?.personId,
    dateOfBirth: memberInfo?.dateOfBirth,
    programCode:
      client === "walmart"
        ? info.eligibility?.isPayroll
          ? info.eligibility?.payrollFrequency ===
            USER_PAYROLL_FREQUENCY.BiWeekly
            ? PROGRAM_CODE.WalmartPayrollBiWeekly
            : PROGRAM_CODE.WalmartPayrollWeekly
          : PROGRAM_CODE.PrimeLTE
        : isStudio
        ? PROGRAM_CODE.Studio
        : client === "hcsc"
        ? PROGRAM_CODE.PrimeSTD
        : client === "bcbsa" || client === "bsca"
        ? PROGRAM_CODE.PrimeFYW
        : client === "mutual-of-omaha"
        ? PROGRAM_CODE.PrimeLGY
        : "",
    consumerId: memberInfo.consumerId,
    clientStateCode: client === "walmart" ? clientStateCode : "",
    clientMemberId: String(memberInfo?.eligibilities[0].clientMemberId),
    subscriberId:
      client === "walmart"
        ? info.eligibility?.associateIdentificationNumber
        : client === "mutual-of-omaha"
        ? info.eligibility?.clientMemberId
        : memberInfo?.eligibilities[0].subscriberId,
    payrollStatus:
      client === "walmart"
        ? info.eligibility?.isPayroll
          ? USER_PAYROLL_STATUS.Payroll
          : USER_PAYROLL_STATUS.NonPayroll
        : USER_PAYROLL_STATUS.NotApplicable,
    orderActions: [
      {
        ratePlanChargeId: info.package?.ratePlanChargeId,
        type: memberInfo.subscriptionAccount?.accountNumber
          ? "SubscriptionNew"
          : "NewAccount",
      },
    ],
  };
}

function createNewMemberFromDependent(
  info: PlanDependent,
  client: unknown,
  parentPlan: FormikWizardState<EligibilityFlow>,
  primaryMember: AccountSummaryMember,
  billingInfo?: IAddressFields,
  soldToInformation?: IAddressFields
) {
  return {
    billToContact: {
      lastName: parentPlan.eligibility?.lastName ?? "",
      firstName: parentPlan.eligibility?.firstName ?? "",
      address1: billingInfo?.street1 ?? "",
      address2: billingInfo?.street2 ?? "",
      city: billingInfo?.city ?? "",
      postalCode: billingInfo?.zip ?? "",
      state: billingInfo?.state ?? "",
    },

    personId: info?.personId,
    consumerId: uuidv4(),
    lastName: info.lastName ?? "",
    firstName: info.firstName ?? "",
    isPrimary: false,
    dateOfBirth: info.dateOfBirth ?? "",
    clientMemberId: String(info.clientMemberId),
    subscriberId:
      client === "walmart"
        ? parentPlan.eligibility?.associateIdentificationNumber
        : primaryMember?.eligibilities[0].subscriberId,
    orderActions: [
      {
        type:
          //@ts-expect-error Api is returning an integer
          info.personId === "0" || info.personId === 0
            ? "NewAccount"
            : "SubscriptionNew",
        ratePlanChargeId: info.ratePlanChargeId
          ? info.ratePlanChargeId
          : parentPlan.package?.ratePlanChargeId,
      },
    ],
    soldToContact: {
      firstName: info.firstName ?? "",
      lastName: info.lastName ?? "",
      address1: soldToInformation?.street1 ?? "",
      address2: soldToInformation?.street2 ?? "",
      city: soldToInformation?.city ?? "",
      postalCode: soldToInformation?.zip ?? "",
      state: soldToInformation?.state ?? "",
      email: info.email ? info.email : parentPlan.eligibility?.email,
    },
    programCode: getDependentProgramCode(
      client,
      parentPlan.eligibility?.isPayroll,
      info.sku,
      parentPlan.package?.sku,
      parentPlan.eligibility?.payrollFrequency
    ),
  };
}

const Checkout = ({ previous }: { previous?: () => void }) => {
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [attestationOpen, setAttestationOpen] = useState(true);
  const [attestations, setAttestations] = useState<
    Array<{ priority: number; text: string }>
  >([]);
  const { user } = useContext(UserContext);
  const [, setPaymentToken] = useState(null);
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const { error: summaryError } = useSummary();
  const history = useHistory();
  const { values, setFieldValue, errors, touched, handleChange } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();
  const [openModal, setOpenModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);
  const [openSomethingModal] = useState(false);
  const { member, clientStateCode } = useContext(AccountContext);
  const { values: impersonationValues } = useContext(ImpersonationContext);
  const [headsUp, setHeadsUp] = useState(false);

  const token = useAuthToken();
  const date = new Date();
  let dependent;

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const { success, error, reset } = useCheckOrder();
  const { config } = useConfig();
  const { getAttetestions } = useContentful(config);
  const { isSmallScreen } = useGetScreenSize();
  const {
    isImpersonated,
    loading: csrLoading,
    error: csrError,
    canContinue,
    setMaskDisabled,
    setMaskEnabled,
  } = useIncontact();
  useEffect(() => {
    if (isSmallScreen) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isImpersonated && !values.eligibility?.isPayroll) {
        await setMaskEnabled();
      }
    })();
  }, []);

  const onSuccess = (res) => {
    if (res.refId) {
      let promoCodeList = sessionStorage.getItem("promoCodes") || "[]";
      try {
        promoCodeList = JSON.parse(promoCodeList);
        if (!Array.isArray(promoCodeList)) {
          promoCodeList = [promoCodeList as string][0];
        }
      } catch (e) {
        promoCodeList = [promoCodeList as string][0];
      }
      setProgress(0);
      setIntervalId(setInterval(() => setProgress((p) => p + 25), 300));
      setShow(true);
      setPaymentToken(res.refId);
      let billToContact = values.address?.billing;
      if (values.address?.billing.city === "") {
        billToContact = values.address?.home;
      }
      const soldToContact = values.address?.home;
      const isStudio = values?.package?.sku === PROGRAM_CODE.Studio;
      if (!member?.personId && values.eligibility?.email) {
        const deps = values?.eligibility?.dependents as PlanDependent[];

        const primary = {
          billToContact: {
            lastName: values.eligibility?.lastName,
            firstName: values.eligibility?.firstName,
            address1: billToContact?.street1,
            address2: billToContact?.street2,
            city: billToContact?.city,
            postalCode: billToContact?.zip,
            state: billToContact?.state,
          },
          consumerId: values.eligibility?.consumerId,
          clientMemberId: String(values.eligibility?.clientMemberId),
          lastName: values.eligibility?.lastName,
          firstName: values.eligibility?.firstName,
          isPrimary: true,
          dateOfBirth: `${values.eligibility?.year}-${values.eligibility?.month}-${values.eligibility?.day}`,
          orderActions: [
            {
              type: "NewAccount",
              ratePlanChargeIds: [values.package?.ratePlanChargeId],
            },
          ],
          soldToContact: {
            firstName: values.eligibility?.firstName,
            lastName: values.eligibility?.lastName,
            address1: soldToContact?.street1,
            address2: soldToContact?.street2,
            city: soldToContact?.city,
            postalCode: soldToContact?.zip,
            state: soldToContact?.state,
            email: values.eligibility?.email,
          },
          programCode:
            config["client"] === "walmart"
              ? values.eligibility?.isPayroll
                ? values.eligibility?.payrollFrequency ===
                  USER_PAYROLL_FREQUENCY.BiWeekly
                  ? PROGRAM_CODE.WalmartPayrollBiWeekly
                  : PROGRAM_CODE.WalmartPayrollWeekly
                : PROGRAM_CODE.PrimeLTE
              : isStudio
              ? PROGRAM_CODE.Studio
              : config["client"] === "hcsc"
              ? PROGRAM_CODE.PrimeSTD
              : config["client"] === "bcbsa" || config["client"] === "bsca"
              ? PROGRAM_CODE.PrimeFYW
              : config["client"] === "mutual-of-omaha"
              ? PROGRAM_CODE.PrimeLGY
              : "",
          groupId: values.eligibility.groupId,
          groupName: values.eligibility.groupName,
          planId: values.eligibility.planId,
          planName: values.eligibility.planName,
          subProduct: values.eligibility.subProduct,
          divisionId: values.eligibility.divisionId,
          fitnessOptIn: values.eligibility.fitnessOptIn,
          misc3: values.eligibility.misc3,
          misc6: values.eligibility.misc6,
          misc7: values.eligibility.misc7,
          misc9: values.eligibility.misc9,
        };

        if (deps) {
          const eligibleDependents = deps
            ?.filter(
              (dependent) =>
                (dependent.status === "Eligible" && dependent.isChosen) ||
                (dependent.status === "UnderAgeDependent" && dependent.isChosen)
            )
            .map((eligibleDependent) => ({ ...eligibleDependent }));

          dependent = eligibleDependents?.map((dependent) => ({
            billToContact: {
              lastName: values?.eligibility?.lastName ?? "",
              firstName: values?.eligibility?.firstName ?? "",
              address1: billToContact?.street1 ?? "",
              address2: billToContact?.street2 ?? "",
              city: billToContact?.city ?? "",
              postalCode: billToContact?.zip ?? "",
              state: billToContact?.state ?? "",
            },
            consumerId: uuidv4(),
            lastName: dependent.lastName ?? "",
            firstName: dependent.firstName ?? "",
            isPrimary: false,
            dateOfBirth: dependent.dateOfBirth ?? "",
            clientMemberId: String(dependent.clientMemberId),
            orderActions: [
              {
                type: "NewAccount",
                ratePlanChargeIds: [
                  dependent.ratePlanChargeId
                    ? dependent.ratePlanChargeId
                    : values.package?.ratePlanChargeId,
                ],
              },
            ],
            soldToContact: {
              firstName: dependent.firstName ?? "",
              lastName: dependent.lastName ?? "",
              address1: soldToContact?.street1 ?? "",
              address2: soldToContact?.street2 ?? "",
              city: soldToContact?.city ?? "",
              postalCode: soldToContact?.zip ?? "",
              state: soldToContact?.state ?? "",
              email: dependent.email
                ? dependent.email
                : values.eligibility?.email,
            },
            programCode: getDependentProgramCode(
              config["client"],
              values.eligibility?.isPayroll,
              dependent.sku,
              values.package?.sku,
              values.eligibility?.payrollFrequency
            ),
          }));
        }

        let payloadMembers;

        if (dependent) {
          payloadMembers = [primary, ...dependent];
        } else {
          payloadMembers = [primary];
        }

        const body = {
          payrollStatus:
            config["client"] === "walmart"
              ? values.eligibility?.isPayroll
                ? USER_PAYROLL_STATUS.Payroll
                : USER_PAYROLL_STATUS.NonPayroll
              : USER_PAYROLL_STATUS.NotApplicable,
          subscriberId:
            config["client"] === "walmart"
              ? values.eligibility?.associateIdentificationNumber
              : config["client"] === "mutual-of-omaha"
              ? values.eligibility.clientMemberId
              : values.eligibility?.subscriberId,
          programCode:
            config["client"] === "walmart"
              ? values.eligibility?.isPayroll
                ? values.eligibility?.payrollFrequency ===
                  USER_PAYROLL_FREQUENCY.BiWeekly
                  ? PROGRAM_CODE.WalmartPayrollBiWeekly
                  : PROGRAM_CODE.WalmartPayrollWeekly
                : PROGRAM_CODE.PrimeLTE
              : config["client"] === "bcbsa" || config["client"] === "bsca"
              ? PROGRAM_CODE.PrimeFYW
              : config["client"] === "mutual-of-omaha"
              ? PROGRAM_CODE.PrimeLGY
              : isStudio
              ? PROGRAM_CODE.Studio
              : config["client"] === "hcsc"
              ? PROGRAM_CODE.PrimeSTD
              : "",
          promoCodes: promoCodeList,
          paymentMethodId: res.refId, //token for iframe
          clientMemberId: String(values.eligibility?.clientMemberId),
          clientStateCode: values.eligibility?.clientStateCode,
          csrUsername: isImpersonated
            ? impersonationValues.csrUsername
            : undefined,
          members: payloadMembers,
          groupId: values.eligibility.groupId,
          groupNumber: values.eligibility.groupNumber,
          planId: values.eligibility.planId,
          planName: values.eligibility.planName,
          subProduct: values.eligibility.subProduct,
          divisionId: values.eligibility.divisionId,
          corporateEntityCode: values.eligibility.corpCode,
          groupName: values.eligibility.groupName,
          fitnessOptIn: values.eligibility.fitnessOptIn,
          groupIndividualIndicator: values.eligibility.groupIndividualIndicator,
          lineOfBusiness: values.eligibility.lineOfBusiness,
          medicareType: values.eligibility.medicareType,
          misc3: values.eligibility.misc3,
          misc6: values.eligibility.misc6,
          misc7: values.eligibility.misc7,
          misc9: values.eligibility.misc9,
        };

        axiosInstance
          .post("/enrollment", body)
          .then(async (response) => {
            if (response.data) {
              axiosPolling(axiosInstance, {
                retryLimit: 15,
                delay: 5000,
              });

              // create polling
              const { emit, on, off, remove } = axiosInstance.poll({
                url: `/enrollment/status/${response.data.executionId}`,
              });
              // send polling
              // Transmit config will be merged with poll config, transmit config takes precedence
              emit();
              const timer = setTimeout(() => {
                // console.log("This will run after 60 seconds!");
                setShow(false);
                setShowError(true);
                off();
                // remove all on
                remove();
              }, 120000);
              // watch request
              on("response", (response) => {
                if ((response.data.status as unknown as string) == "SUCCESS") {
                  clearTimeout(timer);
                  if (values.package?.sku !== PROGRAM_CODE.Studio) {
                    axiosBaseInstance
                      .get(
                        `/clients/${config["client"]}/members/card/preview?personId=${response.data.data.personId}&cardAccessToken=${response.data.data.cardAccessToken}`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      )
                      .then((response) => {
                        values.card = response.data;
                        setFieldValue("checkout.completed", true);
                        if (
                          localStorage.getItem("freeAccountUpgrade") === "true"
                        ) {
                          history.push("/account");
                        } else {
                          history.push("/success", response.data);
                        }
                      })
                      .catch((e) => console.log(e)); // WRITE ERROR CODE
                  } else {
                    setFieldValue("checkout.completed", true);
                    if (localStorage.getItem("freeAccountUpgrade") === "true") {
                      history.push("/account");
                    } else {
                      history.push("/success", response.data);
                    }
                  }

                  off();
                  // remove all on
                  remove();
                }
                if ((response.data.status as unknown as string) == "FAILED") {
                  if (
                    !values.eligibility?.isPayroll &&
                    response.data.error === "CommercePaymentError"
                  ) {
                    clearTimeout(timer);
                    setShow(false);
                    setOpenFailModal(true);
                    off();
                    remove();
                  }
                  clearTimeout(timer);
                  setShow(false);
                  setShowError(true);
                  off();
                  remove();
                }
              });
              // watch error
              on("error", (error) => {
                console.log(error);
              });
            }
          })
          .catch((e) => console.log(e));
      } else if (
        member?.personId &&
        values.eligibility?.email &&
        token &&
        token
      ) {
        const deps = values?.eligibility?.dependents as PlanDependent[];

        if (deps) {
          const eligibleDependents = deps
            ?.filter(
              (dependent) =>
                (dependent.status === "Eligible" && dependent.isChosen) ||
                (dependent.status === "UnderAgeDependent" && dependent.isChosen)
            )
            .map((eligibleDependent) => ({ ...eligibleDependent }));

          dependent = eligibleDependents?.map((dependent) => {
            return createNewMemberFromDependent(
              dependent,
              config["client"],
              values,
              member,
              billToContact,
              soldToContact
            );
          });
        } else {
          // Clear dependent if they do not have a dependent
          dependent = [];
        }
        // Create members account/subscriptions body
        const members = [
          createNewMemberFromForm(
            values,
            config["client"],
            member,
            clientStateCode,
            billToContact,
            soldToContact
          ),
          ...dependent,
        ];

        axiosInstance
          .put(
            "/account/subscriptions",
            {
              paymentMethodId: res.refId, //token for iframe
              members: members,
              promoCodes: promoCodeList,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (response) => {
            if (response.data) {
              axiosPolling(axiosInstance, {
                retryLimit: 15,
                delay: 5000,
              });

              // create polling
              const { emit, on, off, remove } = axiosInstance.poll({
                url: `/account/subscriptions/status/${response.data.executionId}`,
              });
              // send polling
              // Transmit config will be merged with poll config, transmit config takes precedence
              emit();
              const timer = setTimeout(() => {
                // console.log("This will run after 60 seconds!");
                setShow(false);
                setShowError(true);
                off();
                // remove all on
                remove();
              }, 120000);
              // watch request
              on("response", (response) => {
                if ((response.data.status as unknown as string) == "SUCCESS") {
                  clearTimeout(timer);
                  setFieldValue("checkout.completed", true);
                  history.push("/account");
                  off();
                  // remove all on
                  remove();
                }
                if ((response.data.status as unknown as string) == "FAILED") {
                  if (
                    !values.eligibility?.isPayroll &&
                    response.data.error === "CommercePaymentError"
                  ) {
                    clearTimeout(timer);
                    setShow(false);
                    setOpenFailModal(true);
                    off();
                    remove();
                  }
                  clearTimeout(timer);
                  setShow(false);
                  setShowError(true);
                  off();
                  remove();
                }
              });
              // watch error
              on("error", (error) => {
                setShow(false);
                setShowError(true);
                console.log(error);
              });
            }
          })
          .catch((e) => console.log(e));
      }
    } else {
      setShowErrorModal(true);
    }
  };

  const onSubmissionFailed = useCallback(() => {
    setShow(false);
    setShowError(false);
    return;
  }, []);

  const onRequestFailed = useCallback(() => {
    setShow(false);
    setShowError(true);
    return;
  }, []);

  const zuora =
    user.personId && user.zuoraAccountId ? useZuoraPayments : useZuora;

  const zuoraArgs = {
    paymentType: paymentMethod,
    onSuccess,
    onSubmissionFailed,
    onRequestFailed,
  };

  const { render, handleSubmit, hasRendered, paymentOptionTypes } = zuora(zuoraArgs);
  const paymentOptions = paymentOptionTypes?.filter((payment)=>payment?.pageType === "E-Check") || []
  const hasECheckOption = paymentOptions?.length > 0

  useEffect(() => {
    (async () => {
      if (success) {
        localStorage.removeItem("enrollment-flow");
        localStorage.removeItem("ssoInfo");
        if (isImpersonated) {
          try {
            await setMaskDisabled();
            sessionStorage.removeItem("enrollment-flow");
            sessionStorage.removeItem("pipeline-session-id");
            history.push("/success");
          } catch (e) {
            setShow(false);
            setShowError(true);
          }
        } else {
          sessionStorage.removeItem("enrollment-flow");
          sessionStorage.removeItem("pipeline-session-id");
          history.push("/success");
        }
      }
      if (error) {
        if (isImpersonated) await setMaskDisabled();
        setShow(false);
        setShowError(true);
      }
    })();
  }, [success, error]);

  useEffect(() => {
    (async () => {
      getAttetestions(contentfulConfig.ids.attestation).then((res) => {
        if (res?.fields?.attestation) {
          const attest: Array<{ priority: number; text: string }> =
            res.fields.attestation.map((att) => {
              return {
                priority: att.fields?.priority as number,
                text: att.fields?.text as string,
              };
            });
          setAttestations(attest);
        }
      });
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (show && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, show]);

  useEffect(() => {
    if (!csrLoading) {
      render();
    }
  }, [render, paymentMethod, csrLoading]);

  const payrollSubmit = () => {
    if (isImpersonated) setMaskDisabled();
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 25), 300));
    setShow(true);
    let billToContact = values.address?.billing;
    if (values.address?.billing.city === "") {
      billToContact = values.address?.home;
    }
    const soldToContact = values.address?.home;
    if (
      !member?.personId &&
      values.eligibility?.email &&
      config["client"] === "walmart"
    ) {
      axiosInstance
        .post("/enrollment", {
          payrollStatus: values.eligibility?.isPayroll
            ? USER_PAYROLL_STATUS.Payroll
            : USER_PAYROLL_STATUS.NonPayroll,
          subscriberId: values.eligibility?.associateIdentificationNumber,
          clientMemberId: String(values.eligibility?.clientMemberId),
          clientStateCode: values.eligibility?.clientStateCode,
          csrUsername: isImpersonated
            ? impersonationValues.csrUsername
            : undefined,
          members: [
            {
              billToContact: {
                firstName: values.eligibility?.firstName,
                lastName: values.eligibility?.lastName,
                address1: billToContact?.street1,
                address2: billToContact?.street2,
                city: billToContact?.city,
                postalCode: billToContact?.zip,
                state: billToContact?.state,
              },
              consumerId: values.eligibility?.consumerId,
              lastName: values.eligibility?.lastName,
              firstName: values.eligibility?.firstName,
              dateOfBirth: `${values.eligibility?.year}-${values.eligibility?.month}-${values.eligibility?.day}`,
              isPrimary: true,
              orderActions: [
                {
                  type: "NewAccount",
                  ratePlanChargeIds: [values.package?.ratePlanChargeId],
                },
              ],
              soldToContact: {
                firstName: values.eligibility?.firstName,
                lastName: values.eligibility?.lastName,
                address1: soldToContact?.street1,
                address2: soldToContact?.street2,
                city: soldToContact?.city,
                postalCode: soldToContact?.zip,
                state: soldToContact?.state,
                email: values.eligibility?.email,
              },
              programCode: values.eligibility?.isPayroll
                ? values.eligibility?.payrollFrequency ===
                  USER_PAYROLL_FREQUENCY.BiWeekly
                  ? PROGRAM_CODE.WalmartPayrollBiWeekly
                  : PROGRAM_CODE.WalmartPayrollWeekly
                : PROGRAM_CODE.PrimeLTE,
            },
          ],
        })
        .then(async (response) => {
          if (response.data) {
            axiosPolling(axiosInstance, {
              retryLimit: 15,
              delay: 5000,
            });

            // create polling
            const { emit, on, off, remove } = axiosInstance.poll({
              url: `/enrollment/status/${response.data.executionId}`,
            });
            // send polling
            // Transmit config will be merged with poll config, transmit config takes precedence
            emit();
            const timer = setTimeout(() => {
              // console.log("This will run after 60 seconds!");
              setShow(false);
              setShowError(true);
              off();
              // remove all on
              remove();
            }, 60000);

            // watch request
            on("request", () => {
              // console.log(config.poling?.count);
            });
            // watch response
            on("response", (response) => {
              if ((response.data.status as unknown as string) == "SUCCESS") {
                clearTimeout(timer);
                axiosBaseInstance
                  .get(
                    `/clients/${config["client"]}/members/card/preview?personId=${response.data.data.personId}&cardAccessToken=${response.data.data.cardAccessToken}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((response) => {
                    values.card = response.data;
                    setFieldValue("checkout.completed", true);
                    history.push("/success", response.data);
                  })
                  .catch((e) => console.log(e));
                off();
                // remove all on
                remove();
              }
              if ((response.data.status as unknown as string) == "FAILED") {
                setShow(false);
                setShowError(true);
                clearTimeout(timer);
              }
            });
            // watch error
            on("error", (error) => {
              console.log(error);
            });
          }
        })
        .catch((e) => console.log(e));
    } else if (
      member?.personId &&
      values.eligibility?.email &&
      token &&
      config["client"] === "walmart"
    ) {
      axiosInstance
        .put(
          "/account/subscriptions",
          {
            members: [
              {
                personId: member?.personId,
                consumerId: member?.consumerId,
                clientStateCode: clientStateCode,
                subscriberId: values.eligibility?.associateIdentificationNumber,
                payrollStatus: values.eligibility?.isPayroll
                  ? USER_PAYROLL_STATUS.Payroll
                  : USER_PAYROLL_STATUS.NonPayroll,
                orderActions: [
                  {
                    ratePlanChargeId: values.package?.ratePlanChargeId,
                    type: "SubscriptionNew",
                  },
                ],
              },
            ],
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (response) => {
          if (response.data) {
            axiosPolling(axiosInstance, {
              retryLimit: 15,
              delay: 5000,
            });

            // create polling
            const { emit, on, off, remove } = axiosInstance.poll({
              url: `/account/subscriptions/status/${response.data.executionId}`,
            });
            // send polling
            // Transmit config will be merged with poll config, transmit config takes precedence
            emit();
            const timer = setTimeout(() => {
              // console.log("This will run after 60 seconds!");
              setShow(false);
              setShowError(true);
              off();
              // remove all on
              remove();
            }, 120000);
            // watch request
            on("response", (response) => {
              if ((response.data.status as unknown as string) == "SUCCESS") {
                clearTimeout(timer);
                setFieldValue("checkout.completed", true);
                history.push("/account");
                off();
                // remove all on
                remove();
              }
              if ((response.data.status as unknown as string) == "FAILED") {
                if (
                  !values.eligibility?.isPayroll &&
                  response.data.error === "CommercePaymentError"
                ) {
                  clearTimeout(timer);
                  setShow(false);
                  setOpenFailModal(true);
                  off();
                  remove();
                }
                clearTimeout(timer);
                setShow(false);
                setShowError(true);
                off();
                remove();
              }
            });
            // watch error
            on("error", (error) => {
              setShow(false);
              setShowError(true);
              console.log(error);
            });
          }
        })
        .catch((e) => console.log(e));
    } else {
      setShowErrorModal(true);
    }

    handleSubmit;
  };

  const handleCancelError = () => {
    reset();
    setShowError(false);
    setShowErrorModal(false);
  };
  const handlePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(e.target.value);
  };
  const handlePrevious = async () => {
    if (isImpersonated) await setMaskDisabled();
    if (previous) {
      setFieldValue("membership.completed", false);
      previous();
    }
  };

  const CSRState = () => {
    if (csrLoading) {
      <div className="center-loading">
        <Spinner animation="border" />
      </div>;
    }
    if (csrError) {
      return (
        <div>
          <h5 style={{ color: "red" }}>
            Issue with call recording session. Please contact your
            administrator.
          </h5>
        </div>
      );
    }
    if (!canContinue) {
      return (
        <div>
          <h5>
            Issue with call recording session. Please contact your
            administrator.
          </h5>
        </div>
      );
    }
    return null;
  };
  const handleZuora = () => {
    window.open("https://www.zuora.com/", "_blank");
    setOpenModal(false);
  };

  const handleZuoraCancel = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (isImpersonated) {
      setHeadsUp(true);
    }
  }, []);

  return (
    <div>
      <Row>
        <Col xs={12} md={7}>
          {values.eligibility?.isPayroll ? (
            <>
              <Header
                style={{ margin: "20px 0 20px" }}
                title="Authorization For Payroll Deduction"
                subtitle="Please review the information below and agree to our terms to complete checkout."
              />
              {isSmallScreen && (
                <Col xs={12} style={{ marginBottom: "10px" }}>
                  <Summary />
                </Col>
              )}
              {headsUp && (
                <Alert className="primary-member-alert-info" variant="primary">
                  <b>Heads up!</b> You cannot agree to payroll authorization on
                  behalf of the member. Please refer the member to the website
                  to complete the enrollment process.
                </Alert>
              )}
              <h5>Personal Info</h5>
              <Card
                style={{
                  marginBottom: 40,
                  boxShadow: "0 2px 8px #ddd",
                  border: "1px solid rgba(0,0,0,.122)",
                  borderRadius: 5,
                }}
              >
                {csrLoading || csrError || !canContinue ? (
                  <Fragment>{CSRState()}</Fragment>
                ) : (
                  <Card.Body
                    className="checkout-card"
                    style={
                      hasRendered ? { padding: "24px" } : { padding: "20px" }
                    }
                  >
                    <Row className="eligibility-form__row">
                      <Form.Group
                        as={Col}
                        controlId="firstName"
                        xs={12}
                        md={6}
                        lg={6}
                        className="mb-3"
                      >
                        <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                          First Name
                          <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          maxLength={35}
                          name="eligibility.firstName"
                          readOnly
                          value={values.eligibility!.firstName}
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="lastName"
                        xs={12}
                        md={6}
                        lg={6}
                      >
                        <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                          Last Name
                          <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          maxLength={60}
                          name="eligibility.lastName"
                          value={values.eligibility!.lastName}
                          readOnly
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                      <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                        Today's Date
                        <span className="required-field-marker">*</span>
                      </Form.Label>
                      <Form.Group
                        as={Col}
                        controlId="month"
                        xs={3}
                        sm={2}
                        md={2}
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="eligibility.month"
                          value={month}
                          readOnly
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="day" xs={3} sm={2} md={2}>
                        <Form.Control
                          type="text"
                          name="eligibility.day"
                          value={day}
                          readOnly
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="year" xs={4} md={3}>
                        <Form.Control
                          type="text"
                          name="eligibility.year"
                          value={year}
                          readOnly
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                    </Row>
                  </Card.Body>
                )}
              </Card>
              <h5>Agree to Our Terms</h5>
              <Card
                style={{
                  marginBottom: 40,
                  boxShadow: "0 2px 8px #ddd",
                  border: "1px solid rgba(0,0,0,.122)",
                  borderRadius: 5,
                }}
                className="terms-container"
              >
                <Card.Body>
                  <Card.Text>
                    <div className="form-check">
                      <Form.Check
                        inline
                        isInvalid={
                          getIn(touched, "checkout.fitnessPolicy") &&
                          getIn(errors, "checkout.fitnessPolicy")
                        }
                        name="checkout.fitnessPolicy"
                        checked={values.checkout!.fitnessPolicy === true}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, "checkout.fitnessPolicy")}
                      </Form.Control.Feedback>
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                        style={{ maxWidth: `85%` }}
                      >
                        By checking this box, I acknowledge that I have read and
                        agree to comply with the terms and conditions of the{" "}
                        {/* <a href="/agreement" target="_blank" className="a-link">
                          Fitness Program Agreement
                        </a> */}
                        <span
                          style={{
                            textDecoration: "underline",
                            // color: "primary",
                            cursor: "pointer",
                          }}
                          onClick={() => setAttestationOpen(!attestationOpen)}
                          className="a-link"
                        >
                          Payroll Deduction{" "}
                        </span>
                        <span className="a-link" style={{ padding: "5px" }}>
                          &and;
                        </span>
                      </label>
                      {attestationOpen ? (
                        <ol type="1" style={{ marginTop: "10px" }}>
                          {attestations.map((attest) => (
                            <li
                              style={{
                                marginBottom: `10px`,
                                fontWeight: "bold",
                                listStyleType: "number",
                              }}
                            >
                              <span style={{ fontWeight: "normal" }}>
                                {attest.text}
                              </span>
                            </li>
                          ))}
                        </ol>
                      ) : null}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </>
          ) : (
            <>
              <Header
                style={{ margin: "20px 0" }}
                title="Add Payment Method"
                subtitle="How would you like to pay? Choose your preferred monthly payment method"
              />
              {isSmallScreen && (
                <Col xs={12} style={{ marginBottom: "10px" }}>
                  <Summary />
                </Col>
              )}
              <Card>
                {csrLoading || csrError || !canContinue ? (
                  <Fragment>{CSRState()}</Fragment>
                ) : (
                  <Card.Body
                    className="checkout-card"
                    style={
                      hasRendered ? { padding: "24px" } : { padding: "20px" }
                    }
                  >
                    <Row className="eligibility-form__row">
                      <Form.Group className="mb-1">
                        <Form.Label>PAYMENT METHOD</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handlePaymentMethod}
                        >
                          <option value="Credit Card">Credit Card</option>
                          {hasECheckOption && (
                            <option value="E-Check">E-Check</option>
                          )}
                        </Form.Control>
                        {!hasRendered && !summaryError && (
                          <div className="zuora-loading">
                            <Spinner animation="border" />
                          </div>
                        )}
                        {summaryError && (
                          <div className="zuora-center-error">
                            <h2>
                              Sorry, there was an error loading this payment
                              page.
                            </h2>
                          </div>
                        )}
                        {!summaryError && !csrError && (
                          <div id="zuora_payment"></div>
                        )}
                        {hasRendered && (
                          <div className={styles["checkout__zuoraText"]}>
                            Powered by{" "}
                            <a
                              className={styles["checkout__zuoraButton"]}
                              onClick={() => setOpenModal(true)}
                            >
                              Zuora
                            </a>
                          </div>
                        )}
                      </Form.Group>
                    </Row>
                  </Card.Body>
                )}
              </Card>
              {openFailModal ? (
                <FailPaymentModal
                  open={openFailModal}
                  onClose={() => {
                    window.location.reload();
                    setOpenFailModal(!openFailModal);
                  }}
                  isEnrollment={true}
                />
              ) : null}
              {openSomethingModal ? <SomethingWentWrong /> : null}
            </>
          )}
        </Col>
        {!isSmallScreen && (
          <Col xs={12} md={4}>
            <Summary />
          </Col>
        )}
      </Row>
      <FooterButtons
        onSubmit={
          config["client"] === "walmart"
            ? values.eligibility?.isPayroll
              ? payrollSubmit
              : handleSubmit
            : handleSubmit
        }
        onBack={handlePrevious}
        submitDisabled={
          summaryError ||
          (isImpersonated && values.eligibility?.isPayroll === true) ||
          (!values.eligibility?.isPayroll && !hasRendered) ||
          (!values.eligibility?.isPayroll && error) ||
          (values.eligibility?.isPayroll &&
          (values.checkout?.fitnessPolicy ? false : true)
            ? true
            : false)
        }
        submitText="Complete Checkout"
      />
      <StatusModal
        show={showErrorModal}
        status={"GENERIC-ERROR"}
        message=""
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
      <ProcessingOrderModal
        show={show}
        progress={progress}
        onClose={() => {
          return;
        }}
      />
      <StatusModal
        show={showError}
        // show={true}
        status={"ERROR-CHECKOUT"}
        message=""
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
      {openModal && (
        <StatusModal
          show={openModal}
          status={"ZUORA"}
          message=""
          onCancel={handleZuoraCancel}
          onNext={handleZuora}
        />
      )}
    </div>
  );
};

export default Checkout;
