import { createContext, useState, useEffect, useContext } from "react";
import { axiosWHLChoicesVendorInstance } from "../axios.instance";
import { WHLChoicesTokenContext } from "./WHLChoicesTokenProvider";
import { VendorList } from "../types";
import { useConfig } from "../configuration/useConfig";

const VendorContext = createContext<{
  vendors: VendorList[];
  loading: boolean;
}>({ vendors: [], loading: true });

export const WHLChoicesVendorProvider = ({ children }) => {
  const { config } = useConfig();
  const token = useContext(WHLChoicesTokenContext);
  const [vendors, setVendors] = useState<VendorList[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token && config["client"] === "mutual-of-omaha") {
      axiosWHLChoicesVendorInstance
        .get(`/api/vendor/GetVendors/1`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setVendors(response.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [token]);

  return (
    <VendorContext.Provider value={{ vendors, loading }}>
      {children}
    </VendorContext.Provider>
  );
};
export const useVendors = () => useContext(VendorContext);
