import React, { useState, useEffect, useMemo } from "react";
import { IBannerFields } from "../../../@types/generated/contentful";
import { tags } from "../../../utility/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Entry } from "contentful";
import { useConfig } from "../../../configuration/useConfig";
import useContentful from "../../../hooks/useContentful";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const InformationBanner = () => {
  const config = useConfig();
  const { getBanner } = useContentful(config.config);
  const [allPages, setAllPages] = useState(false);
  const [display, setDisplay] = useState(false);
  // Org tags, dictionary of tags for each client containing their item banners
  const [itemTag, setItemTag] = useState({});
  const [show, setShow] = useState(false);
  // Stores basic banner to display
  const [content, setContent] = useState<Entry<IBannerFields>[] | null>(null);
  const { isAuthenticated } = useAuth0();
  const userIsBSCA = config.config["client"] === "bsca";
  const userIsBCBSA = config.config["client"] === "bcbsa";
  // Find specific user org
  const userOrgClient = tags[config.config["client"]];
  const hasOrgSpecificBanners = useMemo(() => {
    return Object.keys(itemTag).includes(userOrgClient);
  }, [itemTag]);

  const location = useLocation();

  useEffect(() => {
    (async () => {
      getBanner().then((res) => {
        if (res) {
          // Stores non org specific banners
          const basicBanners: Entry<IBannerFields>[] = [];
          // Tag dictionary for client specific banners
          const tagDictionary = {};
          // Clients will always have a single banner, except for BCBSA and BSCA, which could have one for each client, or a shared one. If there is more than one banner, get the content based on the tag for each client.
          res.items.forEach((item) => {
            // const clientSpecificTestValue = item.metadata.tags[0]?.sys.id === userOrgClient; // "fywBsca"
            // Can use this to test, note userOrgClient usage in other areas if hard-coding to use "fywBsca"
            if (item.fields.isClientSpecific) {
              const tagId = item.metadata.tags[0]?.sys.id;
              const tagBody = [item];
              if (tagDictionary[tagId]) {
                tagDictionary[tagId].push(tagBody);
              } else {
                tagDictionary[tagId] = tagBody;
              }
              // Set tag dictionary
              setItemTag(tagDictionary);
            } else {
              basicBanners.push(item);
            }
            // Set content
            setContent(basicBanners);
            setAllPages(item.fields.displayAllPages);
            setDisplay(item.fields.displayBanner);
          });
        }
      });
    })();
  }, []);

  useEffect(() => {
    // Display - True
    if (display) {
      // All pages is true
      if (allPages) {
        // isClientSpecific is true
        if (Object.keys(itemTag).length > 0) {
          // Not Authenticated or user is BCBSA
          if (!isAuthenticated || (isAuthenticated && userIsBCBSA)) {
            setShow(true);
          } else {
            setShow(false);
          }
        }
        // isClientSpecific is false
        else {
          setShow(true);
        }
        return;
      }
      // All pages is false
      else {
        // isClientSpecific is true or false has same banner conditions
        // Add if (itemTag) condition if needed to separate isClientSpecific

        // user is authenticated and is BCBSA or BSCA
        if (isAuthenticated && (userIsBCBSA || userIsBSCA)) {
          setShow(true);
        }
        // If allPages is false and on specific page will restrict from showing
        else if (
          (!allPages && location.pathname === "/") ||
          (!allPages && location.pathname === "/enroll/eligibility") ||
          (!allPages && location.pathname === "/how-it-works") ||
          (!allPages && location.pathname === "/faqs") ||
          (!allPages && location.pathname === "/terms") ||
          (!allPages && location.pathname === "/privacy-policy") ||
          (!allPages && location.pathname === "/fitness-program-agreement")
        ) {
          setShow(false);
          return;
        } else {
          setShow(false);
        }
        return;
      }
    }
    // Display - False
    else {
      setShow(false);
      return;
    }
  }, [location, display, allPages, itemTag, isAuthenticated]);

  return (
    <>
      {content && content?.length > 0 && show && (
        <div className="informationBanner">
          {/* Displays regular banners */}
          {content?.map(
            (field) =>
              field.fields.bannerText &&
              documentToReactComponents(field.fields.bannerText)
          )}
        </div>
      )}
      {hasOrgSpecificBanners && itemTag[userOrgClient] && show && (
        <div className="informationBanner">
          {/* Displays org specific banners */}
          {itemTag[userOrgClient]?.map(
            (field) =>
              field.fields.bannerText &&
              documentToReactComponents(field.fields.bannerText)
          )}
        </div>
      )}
    </>
  );
};

export default InformationBanner;
