import NavBar from "./NavBar";
import { Nav } from "react-bootstrap";
import { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext, USER_STATUS } from "../../context/UserProvider";
import NavMenu from "./NavMenu";
import { useConfig } from "../../configuration/useConfig";

const BasicNavHeader = ({
  includeNavMenu,
  showAccount,
  showHome,
  handleOpen,
  handleLogin,
}: {
  includeNavMenu?: boolean;
  showAccount?: boolean;
  showHome?: boolean;
  handleOpen?: () => void;
  handleLogin?: () => void;
}) => {
  const { isAuthenticated } = useAuth0();
  const { user } = useContext(UserContext);
  const { config } = useConfig();

  return (
    <NavBar logo>
      <Nav
        className="justify-content-end"
        style={{
          position: "relative",
          width: "calc(100% + 100px)", // Adjust this value as needed
        }}
      >
        {showAccount && (
          <Nav.Item>
            <Nav.Link
              style={{ alignSelf: "end" }}
              as={NavLink}
              exact={true}
              to="/account"
            >
              My Account
            </Nav.Link>
          </Nav.Item>
        )}
        {showHome && (
          <Nav.Item>
            <Nav.Link as={NavLink} exact={true} to="/">
              Home
            </Nav.Link>
          </Nav.Item>
        )}
        {(isAuthenticated ||
          user.isSSO ||
          config["client"] === "mutual-of-omaha") && showHome && (
            <Nav.Item>
              <Nav.Link
                style={{ whiteSpace: "nowrap" }}
                as={NavLink}
                to="/locations"
              >
                Locations
              </Nav.Link>
            </Nav.Item>
          )}
        {showHome && (
          <Nav.Item>
            <Nav.Link as={NavLink} to="/how-it-works">
              How It Works
            </Nav.Link>
          </Nav.Item>
        )}
        {showHome && (
          <Nav.Item>
            <Nav.Link as={NavLink} to="/faqs">
              FAQs
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
      <Fragment>
        {includeNavMenu && (
          <NavMenu
            onEligibility={
              user.status === USER_STATUS.PENDING ? handleOpen : undefined
            }
            onEnroll={
              user.status === USER_STATUS.ELIGIBLE ? handleOpen : undefined
            }
            onLogin={
              user.isSSO && user.personId === undefined
                ? undefined
                : handleLogin
            }
          />
        )}
      </Fragment>
    </NavBar>
  );
};

export default BasicNavHeader;
