import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useVendors } from "../../context/WHLChoicesVendorProvider";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faCopy,
  faExternalLinkAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const ChoicesDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { vendors } = useVendors();
  const [copied, setCopied] = useState(false);
  const vendor = vendors.find((v) => v.VendorId.toString() === id);

  return (
    <>
      <NavLink
        to="/account/choices"
        style={{
          color: "#44748B",
          fontSize: "16px",
          fontWeight: "bold",
          textDecoration: "none",
          marginBottom: "20px",
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> Back to all discounts
      </NavLink>
      <div>
        <Row style={{ marginLeft: "20px" }}>
          <Col xs={12} md={12} lg={7} className="mt-4 mb-3">
            <h3>{vendor?.VendorName}</h3>
            <div>
              {vendor?.OfferDetails &&
                (() => {
                  try {
                    const offerDetails = JSON.parse(vendor?.OfferDetails);
                    return (
                      <div className="tagline">
                        {offerDetails.tagline
                          .replace(/<\/?p>/g, "")
                          .replace(/<\/?a[^>]*>/g, "")
                          .replace(/<\/?br>/g, "")
                          .replace(/<\/?strong[^>]*>/g, "")}
                      </div>
                    );
                  } catch (e) {
                    console.error("Invalid JSON in OfferDetails", e);
                    return null;
                  }
                })()}
            </div>
          </Col>
        </Row>
        <Row style={{ marginLeft: "20px" }}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={7}
            style={{
              boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              alignSelf: "flex-start",
              padding: "20px 25px",
            }}
          >
            <div>
              <h5>Details</h5>
              {vendor?.OfferDetails &&
                (() => {
                  try {
                    const offerDetails = JSON.parse(vendor?.OfferDetails);
                    return (
                      <div className="details">
                        {offerDetails.details.features.map(
                          (feature: string, index: number) => (
                            <p key={index}>{feature}</p>
                          )
                        )}
                      </div>
                    );
                  } catch (e) {
                    console.error("Invalid JSON in OfferDetails", e);
                    return null;
                  }
                })()}
            </div>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 4, offset: 1 }}
            className="d-flex flex-column align-items-center"
            style={{
              borderRadius: "5px",
              boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.1)",
              alignSelf: "flex-start",
              paddingLeft: "0",
              paddingRight: "0",
              paddingBottom: "15px",
            }}
          >
            {vendor?.Discount && (
              <Row
                className="w-100 justify-content-center"
                style={{
                  fontSize: "60px",
                  fontWeight: "bold",
                  backgroundColor: "#F7F7F7",
                  margin: "0",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  marginBottom: "5px",
                }}
              >
                {vendor?.Discount}%
              </Row>
            )}

            {vendor?.CouponCode && (
              <Row
                className="justify-content-center"
                style={{
                  border: "1px solid #44748B",
                  borderRadius: "5px",
                  margin: "10px 0",
                  textAlign: "center",
                  width: "90%",
                  padding: "0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: "0",
                    paddingRight: "0",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      padding: "5px 5px",
                      color: "#757575",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    CODE:
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "5px 5px",
                    }}
                  >
                    {vendor?.CouponCode}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#44748B",
                      color: "#FFFFFF",
                      padding: "5px 20px",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(vendor?.CouponCode || "");
                      setCopied(true);
                    }}
                  >
                    {copied ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "white" }}
                      />
                    ) : (
                      <FontAwesomeIcon icon={faCopy} />
                    )}{" "}
                    {copied ? "Copied" : "Copy"}
                  </div>
                </div>
              </Row>
            )}
            {vendor?.VendorLink && (
              <Row
                className="justify-content-center"
                style={{
                  border: "1px solid #44748B",
                  borderRadius: "5px",
                  padding: "10px",
                  margin: "10px 0",
                  textAlign: "center",
                  width: "90%",
                }}
              >
                <a
                  href={vendor?.VendorLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#44748B",
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                    textDecoration: "none",
                  }}
                >
                  Visit {vendor?.VendorName}{" "}
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    style={{
                      color: "#44748B",
                      textDecoration: "none",
                      fontSize: "10px",
                      marginBottom: "6px",
                    }}
                  />
                </a>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChoicesDetails;
