import "./index.css";

import App from "./App";
import ConfigProvider from "./configuration/useConfig";
import { ErrorBoundary } from "react-error-boundary";
import React from "react";
import ReactDOM from "react-dom";
import SomethingWentWrong from "./pages/SomethingWentWrong";
import ThemeProvider from "./context/ThemeProvider";
import reportWebVitals from "./reportWebVitals";
import ImpersonationProvider from "./context/ImpersonationProvider";
const logError = (error: Error) => {
  // Do something with the error, e.g. log to an external API
  console.log({ error });
};
ReactDOM.render(
  <ErrorBoundary FallbackComponent={SomethingWentWrong} onError={logError}>
    <ConfigProvider>
      <ThemeProvider>
        <ImpersonationProvider>
          <App />
        </ImpersonationProvider>
      </ThemeProvider>
    </ConfigProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
