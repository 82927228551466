import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { Product } from "../../../api/enrollment/Queries";
import YellowLaptop from "../../../resources/YellowLaptopDigitalPackage.svg";
import useGetScreenSize from "../../../hooks/useGetScreenSize";
import { USER_PAYROLL_FREQUENCY } from "../../../context/UserProvider";

const PackageCard = ({
  productInfo,
  name,
  price,
  handleChange,
  updateTier,
  selected,
  loading,
  isPayroll,
  payrollFrequency,
}: {
  productInfo?: Product;
  name: string;
  className: string;
  price: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updateTier?: (Product: Product) => void;
  selected: string;
  loading: boolean;
  isPayroll: boolean;
  payrollFrequency?: USER_PAYROLL_FREQUENCY;
  isIndividualStudioPurchaseAllowed: boolean;
}) => {
  const { isSmallScreen } = useGetScreenSize();

  return (
    <>
      {loading ? (
        <div className="center-loading">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {productInfo && productInfo.tier !== 0 && (
            <label htmlFor={name} className="package-card-label">
              <Card
                className={`card ${
                  productInfo?.isBestValue ? "best-value" : ""
                }`}
              >
                <Card.Body className="body">
                  <Card.Title>{name}</Card.Title>
                  {/* Card.Text wraps children in a <p> tag, throwing the warning validateDOMNesting(...): <div> cannot appear as a descendant of <p> */}
                  <Card.Text style={{ textAlign: "center" }}>
                    <div className="mb-0 p0 price">
                      ${price.toFixed(2)}
                      <sup className="tax-asterisk">*</sup>
                    </div>
                    <div className="p-0 per-month">
                      {isPayroll ? "per paycheck" : "/ month"}
                    </div>
                    <div className="check-button">
                      <label
                        className="btn btn-default"
                        style={
                          selected === name ? { borderColor: "#449D44" } : {}
                        }
                      >
                        <input
                          type="radio"
                          id={name}
                          value={name}
                          name="package.sku"
                          checked={selected === name}
                          onChange={(e) => {
                            handleChange(e);
                            updateTier ? updateTier(productInfo) : null;
                          }}
                        />
                        {selected === name ? (
                          <span style={{ color: "#449D44" }}>
                            {" "}
                            <FontAwesomeIcon icon={faCheckCircle} /> Selected
                          </span>
                        ) : (
                          <span>Select</span>
                        )}
                      </label>
                    </div>
                    {productInfo.isBestValue ? (
                      <div className="best-value-tag">Most Popular!</div>
                    ) : null}
                  </Card.Text>
                </Card.Body>
                <Card.Footer style={{ padding: `0px` }}>
                  <div
                    style={{
                      width: `100%`,
                      background: `#ffffff`,
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                  >
                    <div
                      className="zuora-pricing-package"
                      dangerouslySetInnerHTML={{
                        __html: productInfo.description,
                      }}
                    />
                  </div>
                </Card.Footer>
              </Card>
            </label>
          )}
          {productInfo && productInfo.tier === 0 && !isSmallScreen ? (
            <label htmlFor={productInfo?.name}>
              <div
                className={`digital-content-only-container ${
                  !isPayroll
                    ? "digital-content-only-container__non-payroll"
                    : payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly
                    ? "digital-content-only-container__weekly"
                    : ""
                } mt-3`}
              >
                <div className="digital-content-only-container__tag">
                  {productInfo?.name || "Digital Content Only"}
                </div>
                <div className="digital-content-only-container__content-row">
                  <div className="digital-content-only-container__price-container">
                    <div
                      className={`digital-content-only-container__price ${
                        !isPayroll
                          ? "digital-content-only-container__price__non-payroll"
                          : ""
                      }`}
                    >
                      ${price.toFixed(2)}
                      <sup className="tax-asterisk">*</sup>
                    </div>
                    <div className="digital-content-only-container__interval">
                      {isPayroll ? "per paycheck" : "/ month"}
                    </div>
                  </div>
                  <div className="digital-content-only-container__description-container">
                    <div
                      className={`digital-content-only-container__description${
                        isPayroll ? "__payroll" : ""
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: productInfo?.description,
                      }}
                    />
                  </div>
                  <div className="check-button-digital-wrapper">
                    <div className="check-button-digital">
                      <label
                        className="btn btn-default"
                        style={
                          selected === name ? { borderColor: "#449D44" } : {}
                        }
                      >
                        <input
                          type="radio"
                          id={name}
                          value={name}
                          name="package.sku"
                          checked={selected === name}
                          onChange={(e) => {
                            handleChange(e);
                            updateTier ? updateTier(productInfo) : null;
                          }}
                        />
                        {selected === name ? (
                          <span style={{ color: "#449D44" }}>
                            {" "}
                            <FontAwesomeIcon icon={faCheckCircle} /> Selected
                          </span>
                        ) : (
                          <span>Select</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          ) : productInfo && productInfo.tier === 0 && isSmallScreen ? (
            <label htmlFor={name} className="package-card-label">
              <Card
                className={`card ${
                  productInfo?.isBestValue ? "best-value" : ""
                }`}
              >
                <Card.Body className="body">
                  <Card.Title>{name}</Card.Title>
                  <Card.Text style={{ textAlign: "center" }}>
                    <div className="mb-0 p-0 price">
                      ${price.toFixed(2)}
                      <sup className="tax-asterisk">*</sup>
                    </div>
                    <div className="p-0 per-month">
                      {isPayroll ? "per paycheck" : "/ month"}
                    </div>
                    <div className="check-button">
                      <label
                        className="btn btn-default"
                        style={
                          selected === name ? { borderColor: "#449D44" } : {}
                        }
                      >
                        <input
                          type="radio"
                          id={name}
                          value={name}
                          name="package.sku"
                          checked={selected === name}
                          onChange={handleChange}
                        />
                        {selected === name ? (
                          <span style={{ color: "#449D44" }}>
                            {" "}
                            <FontAwesomeIcon icon={faCheckCircle} /> Selected
                          </span>
                        ) : (
                          <span>Select</span>
                        )}
                      </label>
                    </div>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <div
                    style={{
                      width: `100%`,
                      background: `#ffffff`,
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="feature-package-select__gym-count-container">
                      <img src={YellowLaptop} alt="Yellow Laptop Icon" />
                    </div>
                    <hr style={{ marginLeft: `10%`, marginRight: `10%` }} />
                    <div className="feature-package-select__studio-class-reward">
                      <span>
                        Instant access to thousands of on-demand videos and live
                        streams
                      </span>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </label>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default PackageCard;
