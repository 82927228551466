import React from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useVendors } from "../../context/WHLChoicesVendorProvider";
import { useEffect, useRef } from "react";
import useGetScreenSize from "../../hooks/useGetScreenSize";

const Choices = () => {
  const { vendors } = useVendors();
  const topRef = useRef<HTMLDivElement>(null);
  const { isSmallScreen } = useGetScreenSize();

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, []);

  return (
    <div ref={topRef} style={{ scrollMarginTop: "150px" }}>
      <NavLink
        to="/account"
        style={{
          color: "var(--clientPrimary)",
          fontSize: "16px",
          fontWeight: "bold",
          textDecoration: "none",
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> Back to my account
      </NavLink>
      <Row>
        <h2
          style={
            !isSmallScreen
              ? {
                  marginTop: "25px",
                  marginLeft: "70px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }
              : undefined
          }
        >
          Wellness Discounts
        </h2>
      </Row>
      {vendors?.map((vendor) => (
        <>
          <section
            key={vendor.VendorId}
            className="whl-choices-card mt-3 mb-3"
            style={{ marginLeft: "70px" }}
          >
            <Row>
              <div
                className="whl-choices-row"
                style={{
                  display: "flex",
                  flexDirection: isSmallScreen ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  paddingLeft: 0,
                  marginBottom: "10px",
                  marginLeft: isSmallScreen ? "-40px" : "0",
                }}
              >
                <Col xs={12} md={12} lg={2}>
                  <div
                    style={{
                      backgroundColor: "#EFEFEF",
                      height: "127px",
                      width: "139px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: isSmallScreen ? "10px" : "0",
                    }}
                  >
                    <h1>{vendor.Discount}%</h1>
                  </div>
                </Col>
                <Col
                  style={{ marginLeft: isSmallScreen ? "0" : "-30px" }}
                  xs={12}
                  md={12}
                  lg={8}
                >
                  <div>
                    <h4 style={{ fontWeight: "bold" }}>{vendor.VendorName}</h4>
                    <div>
                      {vendor.OfferDetails &&
                        (() => {
                          try {
                            const offerDetails = JSON.parse(
                              vendor.OfferDetails
                            );
                            return (
                              <div className="tagline">
                                {offerDetails.tagline
                                  .replace(/<\/?p>/g, "")
                                  .replace(/<\/?a[^>]*>/g, "")
                                  .replace(/<\/?br>/g, "")
                                  .replace(/<\/?strong[^>]*>/g, "")}
                              </div>
                            );
                          } catch (e) {
                            console.error("Invalid JSON in OfferDetails", e);
                            return null;
                          }
                        })()}
                    </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  sm={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 4, offset: 1 }}
                >
                  <div>
                    <NavLink
                      style={{
                        color: "#21445C",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                      to={`/account/choices/${vendor.VendorId}`}
                    >
                      View Details <FontAwesomeIcon icon={faChevronRight} />
                    </NavLink>
                  </div>
                </Col>
              </div>
            </Row>
          </section>
        </>
      ))}
      <div style={{ marginBottom: "100px" }}></div>
    </div>
  );
};

export default Choices;
