import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { useConfig } from "../configuration/useConfig";
import axios from "axios";

interface WHLLocationsTokenProviderProps {
  children: ReactNode;
}

export const WHLLocationsTokenContext = createContext<string | null>(null);

export const WHLLocationsTokenProvider: React.FC<
  WHLLocationsTokenProviderProps
> = ({ children }) => {
  const [whlLocationstoken, setWhlLocationstoken] = useState<string | null>(
    null
  );
  const { config } = useConfig();

  const getToken = async () => {
    if (config["client"] === "mutual-of-omaha") {
      try {
        const response = await axios.request({
          url: `https://idp.myflip50.com/identity/connect/token`,
          method: "POST",
          data: {
            grant_type: "password",
            scope: "whl",
            client_id: config["whlChoicesIDPTokenClientId"],
            client_secret:
              "C8D45DcF2P5nD6mD8l0R1b344vAxF0B1wq1DE1jChD0tu52Y4x3p3E2C08ixot7um6TR",
            username: "ASIGuestUser",
            password: "6Fxpe4E6fdp9B3D6cF7480eA4BC4BsnBF0d593x8iC",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        const fetchedToken = response.data.access_token;
        setWhlLocationstoken(fetchedToken);
      } catch (error) {
        console.error("Error getting token", error);
      }
    }
  };
  useEffect(() => {
    getToken();
  }, []);

  return (
    <WHLLocationsTokenContext.Provider value={whlLocationstoken}>
      {children}
    </WHLLocationsTokenContext.Provider>
  );
};

export const useWHLLocationsToken = () => {
  return useContext(WHLLocationsTokenContext);
};
