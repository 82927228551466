import { Tile } from "./Tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import NoPlanTileSVG from "../../../resources/NoPlanTileMW.svg";
import iosIcon from "../../../resources/iosIcon.svg";
import androidIcon from "../../../resources/androidIcon.svg";
import useGetScreenSize from "../../../hooks/useGetScreenSize";

const NoPlanTileMOO = () => {
  const { isMobileScreenSize } = useGetScreenSize();

  return (
    <Tile className="widget__card">
      <div className="no-plan">
        <Tile.THeader>
          <div>
            <FontAwesomeIcon icon={faClipboardCheck} /> <b>MY PLAN</b>
          </div>
        </Tile.THeader>
        <Tile.TBody className="h-auto">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p
              style={{
                fontSize: isMobileScreenSize ? "16px" : "20px",
                color: "#000000",
                fontWeight: "100",
              }}
            >
              No Active Fitness Package
            </p>
            <img
              src={NoPlanTileSVG}
              style={{ marginTop: "10px" }}
              alt="Gym Icon"
            />
            <h5 style={{ marginTop: "25px" }}>
              <b>Free Mobile App</b>
            </h5>
            <p
              className="p-0 text-center font-weight-light"
              style={{ marginTop: "-10px", marginBottom: "-5px" }}
            >
              Download our app to access your weekly guided plan
            </p>
          </div>
        </Tile.TBody>
        <Tile.TFooter>
          <div
            style={{ marginTop: "10px" }}
            className="app-store-button-container"
          >
            <a
              href="https://apps.apple.com/us/app/mutually-well/id1474022850?ls=1"
              target="_blank"
              style={{ marginRight: "20px" }}
            >
              <img
                src={iosIcon}
                alt="IOS"
                style={{
                  width: isMobileScreenSize ? "90px" : "118px",
                  height: "36px",
                }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.omaha.tivity"
              target="_blank"
            >
              <img
                src={androidIcon}
                alt="Android"
                style={{
                  width: isMobileScreenSize ? "90px" : "118px",
                  height: "36px",
                }}
              />
            </a>
          </div>
        </Tile.TFooter>
      </div>
    </Tile>
  );
};

export default NoPlanTileMOO;
