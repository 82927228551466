import React, { useContext } from "react";
// import { useLocation } from "react-router-dom";
import { AccountContext } from "../context/AccountProvider";
import NavBar from "../components/navigation/NavBar";
import { ReactComponent as SuccessImage } from "../resources/WM_Success_Illustration__1_.svg";
import { useLocation } from "react-router-dom";
import { PROGRAM_CODE, UserContext } from "../context/UserProvider";

// interface LocationState {
//   pathname: string;
//   eligibility: {
//     email: string;
//   };
//   card: string;
//   package: {
//     sku: string;
//   };
// }

interface LocationState {
  card: string;
}

const Success = () => {
  const { user } = useContext(UserContext);
  const { error } = useContext(AccountContext);
  const { state } = useLocation<LocationState>();
  const sessionData = sessionStorage.getItem("enrollment-flow");
  const sessionObject = JSON.parse(sessionData ?? "");
  const values = sessionObject.values;

  const handleMembershipCard = () => {
    const image = new Image();
    image.src = `data:image/png;base64,${state}`;
    const w = window.open("");
    w?.document.write(image.outerHTML);
  };

  if (error) {
    return <div>Error: Invalid</div>;
  }
  return (
    <div className="success-container">
      <NavBar logo />
      <div className="success-content">
        <SuccessImage className="success-image" />
        <span className="success-title">Success!</span>
        <h5>
          <b>Please check your email</b>
        </h5>
        <p>We've sent the instructions for completing your account setup to</p>
        <p className="user-email">
          <b>{values.eligibility.email}</b>
        </p>
        {!(
          [
            PROGRAM_CODE.PrimeSTD,
            PROGRAM_CODE.Studio,
            PROGRAM_CODE.PrimeFYW,
            PROGRAM_CODE.PrimeLTE,
          ].includes(values.package.programCode) && values.package.tier === 0
        ) &&
          user.hasMutuallyWellFreeAccount !== true && (
            <div className="member-card-container">
              <p className="member-card-container-text">
                Need quick access to your member card?
              </p>
              <span onClick={handleMembershipCard} className="member-card-link">
                View my member card
              </span>
            </div>
          )}
      </div>
    </div>
  );
};

export default Success;
