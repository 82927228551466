import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReEnrollImg from "./Tiles/assets/reenroll_banner.png";
import { Tile } from "./Tiles/Tile";
import { AccountContext } from "../../context/AccountProvider";
import { useContext } from "react";
import useGetScreenSize from "../../hooks/useGetScreenSize";
import { UserContext } from "../../context/UserProvider";
import { useConfig } from "../../configuration/useConfig";

const UpgradeBanner = () => {
  const { member } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const { config } = useConfig();

  const { isMobileScreenSize } = useGetScreenSize();

  const getUpgradeLink = (): string => {
    const filteredEligibilities = member?.eligibilities.filter(
      (eligibility) => {
        return eligibility.isMembershipActive;
      }
    );
    if (
      (filteredEligibilities &&
        filteredEligibilities.length == 1 &&
        (filteredEligibilities[0].productCode === "260" ||
          filteredEligibilities[0].productCode === "261") &&
        filteredEligibilities[0].isMembershipActive) ||
      ((config["client"] === "mutual-of-omaha" ||
        config["client"] === "hcsc") &&
        member?.personId)
    ) {
      return "/enroll/package";
    }

    if (user.hasMutuallyWellFreeAccount && member?.eligibilities.length === 0) {
      return `/enroll/eligiblity`;
    }
    return "/account/manage";
  };

  return (
    <Tile className="upgrade-banner">
      <Tile.THeader className="upgrade-banner__header">
        <div className="h-100">
          <img
            width="100%"
            height="100%"
            className="upgrade-banner__img"
            src={ReEnrollImg}
            alt="enroll"
          />
        </div>
      </Tile.THeader>
      <Tile.TBody className="upgrade-banner__body">
        <div>
          <h3 className="text-left">
            <b>Upgrade your access & go the gym!</b>
          </h3>
          <hr style={{ width: "103px" }} />
          <p style={{ fontSize: `16px` }} className="text-left p-0 mb-0">
            Upgrade your access today and get nationwide gym access with no
            contract required and thousands of online classes!
          </p>
        </div>
      </Tile.TBody>
      <Tile.TFooter
        className="upgrade-banner__footer"
        // className="w-25 d-flex mb-2 justify-content-end flex-column"
      >
        <div className="d-flex justify-content-between">
          <Col xs={9} style={{ height: "70px" }}>
            <Button
              as={Link}
              style={{
                color: "white",
                fontSize: isMobileScreenSize ? "10px" : "16px",
                height: "35px",
              }}
              className="btn btn-primary align-self-left btn-sm w-100 upgrade-banner__button"
              to={getUpgradeLink()}
            >
              Upgrade My Access
            </Button>
          </Col>
        </div>
      </Tile.TFooter>
    </Tile>
  );
};
export default UpgradeBanner;
