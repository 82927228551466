import React, { Fragment } from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SideNavLink } from "../../routing/AccountRouting";
import { Button } from "react-bootstrap";

const SideNavigation = ({
  avatar,
  links,
  className,
  status,
}: {
  avatar: string;
  links: SideNavLink[];
  name: string;
  status: string;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const currPath = window.location.pathname;

  return (
    <div className={`account-navigation ${className}`}>
      <div className="account-navigation__profile-container">
        <div
          className="account-navigation__profile-avatar"
          style={{ color: "var(--clientPrimary)" }}
        >
          {avatar}
        </div>
        <NavLink
          to="/account/view-profile"
          className="btn-outline-secondary account-navigation__view-profile-link"
          style={{ color: "var(--clientPrimary)" }}
        >
          View Profile
        </NavLink>
      </div>
      <div className="account-navigation__nav-container">
        <Nav activeKey={location.pathname} className="flex-column account-nav">
          {links
            .filter((l) => {
              if (l.disabledForStatus) {
                return !l.disabledForStatus?.includes(status);
              }
              return true;
            })
            .map((link: SideNavLink, index: number) => (
              <Fragment key={index}>
                {!link.onClick &&
                currPath === "/account/manage/edit" &&
                link.label === "Manage Plans" ? (
                  <Nav.Link
                    exact
                    as={NavLink}
                    to={currPath}
                    key={index}
                    target={links[1].newTab ? "_blank" : ""}
                    rel="noreferrer"
                    className={links[1].label === "Log Out" ? "log-out" : ""}
                    activeClassName="account-nav__active"
                  >
                    {links[1].icon && <FontAwesomeIcon icon={links[1].icon} />}
                    {links[1].label}
                  </Nav.Link>
                ) : !link.onClick ? (
                  <Nav.Link
                    exact
                    as={NavLink}
                    to={link.url!}
                    key={index}
                    target={link.newTab ? "_blank" : ""}
                    rel="noreferrer"
                    className={link.label === "Log Out" ? "log-out" : ""}
                    activeClassName="account-nav__active"
                  >
                    {link.icon && <FontAwesomeIcon icon={link.icon} />}
                    {link.label}
                  </Nav.Link>
                ) : (
                  <Button
                    variant="link"
                    onClick={link.onClick}
                    className="logout-button"
                  >
                    {link.label}
                  </Button>
                )}
              </Fragment>
            ))}
        </Nav>
      </div>
    </div>
  );
};

export default SideNavigation;
